import React from "react";
import { connect } from "react-redux";
import Header from "../components/Page/Header";
import { Alert, AlertTitle } from "@material-ui/lab";
function WaitingRoom() {
  return (
    <React.Fragment>
      <Header title="Favorites" />

      <Alert severity="info">
        <AlertTitle>Information</AlertTitle>
        Here you fill find all the dialogues you favored.
        <br />
        These can be dialogues that you always want to bring back to life, or
        even dialogues, which you would like to continue with the next login.
        <br />
        These favorites should be viewed as bookmarks.
        <br />
        You can only lead these dialogues if they have not been assigned by
        otherworldly means.
        <br />
        <strong>
          From now on, several chatters can save the same dialog as a favorite
          for themselves!
        </strong>
      </Alert>
      <Alert severity="success" icon={false} style={{ marginTop: 15 }}>
        <strong>Green</strong> = Free
      </Alert>
      <Alert severity="warning" icon={false} style={{ marginTop: 15 }}>
        <strong>Yellow</strong> = Dialog leads another
      </Alert>
      <Alert severity="error" icon={false} style={{ marginTop: 15 }}>
        <strong>Red</strong> = deleted or team change (can be removed)
      </Alert>

      <Alert severity="info" style={{ marginTop: 15 }}>
        You don't have any favorites
      </Alert>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(WaitingRoom);
