import React from "react";
import { connect } from "react-redux";
import { getReceivedGifts } from "../../redux/actions/giftsActions";
import Table from "../Table";
import Card from "../Card";
import Moment from "react-moment";
function ReceivedGifts(props) {
  const { receivedGifts, receivedGiftsData, loading } = props.gift;
  const columns = [
    {
      id: "image",
      Header: "Gift image",
      accessor: (d) => d._id,
      Cell: (props) => (
        <img
          src={props.original.gift && props.original.gift.image}
          alt={props.original.gift && props.original.gift.title}
          style={{ height: 50 }}
        />
      ),
    },
    {
      id: "title",
      Header: "Gift title",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>{props.original.gift && props.original.gift.title}</strong>
      ),
    },
    {
      id: "price",
      Header: "Gift Price",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>{props.original.gift && props.original.gift.coins}</strong>
      ),
    },
    {
      id: "sentDate",
      Header: "Received Date",
      accessor: (d) => d._id,
      Cell: (props) => <Moment>{props.original.createdAt}</Moment>,
    },
    {
      id: "from",
      Header: "Seit",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.from && props.original.from.profile.name}
        </strong>
      ),
    },
  ];

  return (
    <Card title="Received Gifts" style={{ marginTop: 15 }}>
      <Table
        data={receivedGifts}
        columns={columns}
        loading={loading}
        page={
          !receivedGiftsData.pagination
            ? 0
            : receivedGiftsData.pagination.current - 1
        }
        pages={
          !receivedGiftsData.pagination ? 1 : receivedGiftsData.pagination.total
        }
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&to=${
            props.userId
          }&${queryString}`;
          props.getReceivedGifts(query);
        }}
        noDataText="No received gifts"
        minRows={5}
      />
    </Card>
  );
}

const mapStateToProps = (state) => ({
  gift: state.gift,
});

export default connect(mapStateToProps, { getReceivedGifts })(ReceivedGifts);
