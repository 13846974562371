import {
  GET_CHANNELS,
  CHANNELS_LOADING,
  GET_MESSAGES,
  MESSAGES_LOADING,
} from "../types";
import { DASHBOARD } from "../../config";
import addNotification from "react-push-notification";
const initialState = {
  channels: [],
  data: [],
  messages: [],
  messagesData: [],
  messagesLoading: false,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CHANNELS:
      return {
        ...state,
        channels: action.payload.data,
        data: action.payload,
        loading: false,
      };
    case GET_MESSAGES:
      return {
        ...state,
        messages: action.payload.data,
        messagesData: action.payload,
        messagesLoading: false,
      };
    case "message":
      return {
        ...state,
        messages: [action.data, ...state.messages],
      };
    case "notifyChatter":
      var audio = new Audio(`${DASHBOARD}/audio/message.mp3`);
      audio.volume = 1;
      audio.play();
      addNotification({
        title: "New message",
        subtitle: "New message",
        message: action.data.body,
        theme: "darkblue",
        native: true,
        icon: `${DASHBOARD}/favicon/mstile-150x150.png`,
      });
      return {
        ...state,
      };
    case "notifyNewChannel":
      var audio2 = new Audio(`${DASHBOARD}/audio/message.mp3`);
      audio2.volume = 1;
      audio2.play();
      addNotification({
        title: "New message available in waiting room",
        message: action.data.body,
        theme: "darkblue",
        native: true,
        icon: `${DASHBOARD}/favicon/mstile-150x150.png`,
      });
      return {
        ...state,
      };
    case "notifyPoke":
      var audio3 = new Audio(`${DASHBOARD}/audio/message.mp3`);
      audio3.volume = 1;
      audio3.play();
      addNotification({
        title: "You have been poked",
        message: action.data.lastMessage,
        theme: "darkblue",
        native: true,
        icon: `${DASHBOARD}/favicon/mstile-150x150.png`,
        vibrate: [200, 100, 200],
        duration: 10000,
      });
      return {
        ...state,
      };
    case CHANNELS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case "socketStatus":
      return {
        ...state,
        socketStatus: action.data,
      };
    case MESSAGES_LOADING:
      return {
        ...state,
        messagesLoading: true,
      };
    default:
      return state;
  }
}
