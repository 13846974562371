import axios from "axios";
import {
  GET_GIFTS,
  GET_GIFT,
  ADD_GIFT,
  GIFTS_LOADING,
  DELETE_GIFT,
  GET_GIFTS_RECEIVED,
  GET_GIFTS_SENT,
  UPDATE_GIFT,
} from "../types";
import cogoToast from "cogo-toast";
import { API } from "../../config";
import { tokenConfig } from "./authActions";
import { returnErrors } from "./errorActions";

export const getGifts = (query) => (dispatch) => {
  dispatch(setGiftsLoading());
  axios
    .get(`${API}/gifts${query}`)
    .then((res) =>
      dispatch({
        type: GET_GIFTS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const getSentGifts = (query) => (dispatch) => {
  dispatch(setGiftsLoading());
  axios
    .get(`${API}/sent-gifts${query}`)
    .then((res) =>
      dispatch({
        type: GET_GIFTS_SENT,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const getReceivedGifts = (query) => (dispatch) => {
  dispatch(setGiftsLoading());
  axios
    .get(`${API}/sent-gifts${query}`)
    .then((res) =>
      dispatch({
        type: GET_GIFTS_RECEIVED,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const getGift = (id) => {
  return (dispatch) => {
    axios
      .get(`${API}/gifts/${id}`)
      .then((response) => {
        dispatch({
          type: GET_GIFT,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(
          dispatch(returnErrors(error.response.data, error.response.status))
        );
      });
  };
};

export const activateGift = (id, type) => {
  const data = {
    show: type,
  };
  return (dispatch) => {
    axios
      .put(`${API}/gifts/${id}`, data)
      .then((response) => {
        dispatch({
          type: UPDATE_GIFT,
          payload: response.data.data,
        });
        if (type === true) {
          cogoToast.success("Gift has been activated", {
            position: "top-right",
          });
        } else {
          cogoToast.success("Gift has been disabled", {
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        dispatch(
          dispatch(returnErrors(error.response.data, error.response.status))
        );
      });
  };
};

export const addGift = (data) => (dispatch, getState) =>
  new Promise(function (resolve, reject) {
    axios
      .post(`${API}/gifts`, data, tokenConfig(getState))
      .then((response) => {
        dispatch({
          type: ADD_GIFT,
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((error) => {
        cogoToast.error("Something went wrong", {
          position: "top-right",
        });
        reject(error);
      });
  });

export const deleteGift = (id) => (dispatch, getState) =>
  new Promise(function (resolve, reject) {
    axios
      .delete(`${API}/gifts/${id}`, tokenConfig(getState))
      .then((response) => {
        dispatch({
          type: DELETE_GIFT,
          payload: id,
        });
        resolve(response.data);
      })
      .catch((error) => {
        cogoToast.error("Something went wrong", {
          position: "top-right",
        });
        reject(error);
      });
  });

export const setGiftsLoading = () => {
  return {
    type: GIFTS_LOADING,
  };
};
