import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import useSWR from "swr";
import axios from "axios";
import { API } from "../../config";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "@material-ui/core/Button";
import cogoToast from "cogo-toast";
import Loader from "../LoaderPage";
function AdvertiserLink({ refId }) {
  const handleClick = () => {
    cogoToast.success("Share link copied to clipboard", {
      position: "top-right",
    });
  };

  return (
    <Paper style={{ padding: 30, textAlign: "center", height: "100%" }}>
      <h3>Your share link</h3>
      <a
        href={`https://enjoyflirt.com?refId=${refId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        https://enjoyflirt.com?refId={refId}
      </a>{" "}
      <br />
      <br />
      <CopyToClipboard
        text={`https://enjoyflirt.com?refId=${refId}`}
        onCopy={handleClick}
      >
        <Button color="primary" variant="contained">
          Copy
        </Button>
      </CopyToClipboard>
    </Paper>
  );
}

function Card({ number, title }) {
  return (
    <Paper style={{ padding: 30, textAlign: "center", height: "100%" }}>
      <h1>{number}</h1>
      <p style={{ fontSize: 18 }}>{title}</p>
    </Paper>
  );
}

function Advertiser(props) {
  const { user } = props.auth;
  const { data, error } = useSWR(
    `${API}/info/dashboard-advertiser/${user.refId}`,
    axios
  );
  if (error) return <div></div>;
  if (!data) return <Loader />;
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <AdvertiserLink refId={user.refId} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card number={data.data.total} title="Total registered users" />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            number={data.data.thisMonth}
            title="Total registered users this month"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card number={data.data.totalOnline} title="Total Online" />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card number={user.refVisits} title="Visits On Your Link" />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card number={data.data.totalDeleted} title="Total Deleted" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Advertiser);
