import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
const ErrorPage = styled.div`
  #monkey_404 {
    width: 800px;
    height: 480px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -400px;
    margin-top: -300px;
    text-align: center;
  }
  #monkey_404 .st0 {
    fill: #e8ebed;
  }
  #monkey_404 .st1 {
    fill: #ffffff;
  }
  #monkey_404 .st2 {
    fill: none;
    stroke: #89949b;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  #monkey_404 .st3 {
    fill: #e8ebed;
    stroke: #89949b;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  #monkey_404 .st4 {
    fill: #ffffff;
    stroke: #89949b;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  #monkey_404 .st5 {
    fill: none;
    stroke: #89949b;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  #monkey_404 .st6 {
    fill: none;
    stroke: #89949b;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  #monkey_404 .st7 {
    fill: #ffffff;
    stroke: #89949b;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  #monkey_404 .st8 {
    fill: #89949b;
  }
  #monkey_404 .st9 {
    fill: #89949b;
  }
  #monkey_404 .st10 {
    fill: none;
    stroke: #89949b;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  #monkey_404 .st11 {
    fill: #ffffff;
  }
  #monkey_404 .st12 {
    fill: #ffffff;
    stroke: #8894a0;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  #monkey_404 .st13 {
    fill: #ffffff;
    stroke: #89949b;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  #monkey_404 .st14 {
    fill: none;
    stroke: #89949b;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  #monkey_404 .st15 {
    fill: none;
    stroke: #89949b;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  &.dark {
    #monkey_404 .st0 {
      fill: #46484a;
    }
    #monkey_404 .st1 {
      fill: #242424;
    }
    #monkey_404 .st2 {
      stroke: #89949b;
    }
    #monkey_404 .st3 {
      fill: #46484a;
      stroke: #89949b;
    }
    #monkey_404 .st4 {
      fill: #242424;
      stroke: #89949b;
    }
    #monkey_404 .st5 {
      stroke: #89949b;
    }
    #monkey_404 .st6 {
      stroke: #89949b;
    }
    #monkey_404 .st7 {
      fill: #242424;
      stroke: #89949b;
    }
    #monkey_404 .st8 {
      fill: #89949b;
    }
    #monkey_404 .st9 {
      fill: #89949b;
    }
    #monkey_404 .st10 {
      stroke: #89949b;
    }
    #monkey_404 .st11 {
      fill: #242424;
    }
    #monkey_404 .st12 {
      fill: #242424;
      stroke: #8894a0;
    }
    #monkey_404 .st13 {
      fill: #242424;
      stroke: #89949b;
    }
    #monkey_404 .st14 {
      stroke: #89949b;
    }
    #monkey_404 .st15 {
      stroke: #89949b;
    }
  }
  #monkey_404 #arm {
    -webkit-transform-origin: 155px 292px;
    -moz-transform-origin: 155px 292px;
    -ms-transform-origin: 155px 292px;
    -o-transform-origin: 155px 292px;
    transform-origin: 155px 292px;
    -webkit-transform: rotateZ(-2deg);
    -moz-transform: rotateZ(-2deg);
    -ms-transform: rotateZ(-2deg);
    -o-transform: rotateZ(-2deg);
    transform: rotateZ(-2deg);
  }
  #monkey_404 #monkey {
    -webkit-animation: monkey-breathe 3s infinite ease-in-out;
    -moz-animation: monkey-breathe 3s infinite ease-in-out;
    -o-animation: monkey-breathe 3s infinite ease-in-out;
    animation: monkey-breathe 3s infinite ease-in-out;
  }
  #monkey_404 #zelda,
  #monkey_404 #tetris,
  #monkey_404 #moon,
  #monkey_404 #star_a,
  #monkey_404 #star_b,
  #monkey_404 #star_c,
  #monkey_404 #star_d,
  #monkey_404 #number_4,
  #monkey_404 #number_4_2,
  #monkey_404 #number_0,
  #monkey_404 #sword {
    -webkit-animation: levitate 3s infinite ease-in-out;
    -moz-animation: levitate 3s infinite ease-in-out;
    -o-animation: levitate 3s infinite ease-in-out;
    animation: levitate 3s infinite ease-in-out;
  }
  #monkey_404 #zelda {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
  }
  #monkey_404 #tetris {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
  }
  #monkey_404 #moon {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
  }
  #monkey_404 #star_a {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
  }
  #monkey_404 #star_b {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
  }
  #monkey_404 #star_c {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
  }
  #monkey_404 #star_d {
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    -o-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
  }
  #monkey_404 #number_4 {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
  }
  #monkey_404 #number_4_2 {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
  }
  #monkey_404 #number_0 {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
  }
  #monkey_404 #sword {
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    -o-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
  }
  #monkey_404 #eye_left {
    -webkit-transform-origin: 191px 257px;
    -moz-transform-origin: 191px 257px;
    -ms-transform-origin: 191px 257px;
    -o-transform-origin: 191px 257px;
    transform-origin: 191px 257px;
    -webkit-animation: blink-l 12s infinite ease-in-out;
    -moz-animation: blink-l 12s infinite ease-in-out;
    -o-animation: blink-l 12s infinite ease-in-out;
    animation: blink-l 12s infinite ease-in-out;
  }
  #monkey_404 #eye_right {
    -webkit-transform-origin: 205px 256px;
    -moz-transform-origin: 205px 256px;
    -ms-transform-origin: 205px 256px;
    -o-transform-origin: 205px 256px;
    transform-origin: 205px 256px;
    -webkit-animation: blink-r 12s infinite ease-in-out;
    -moz-animation: blink-r 12s infinite ease-in-out;
    -o-animation: blink-r 12s infinite ease-in-out;
    animation: blink-r 12s infinite ease-in-out;
  }
  lesshat-selector {
    -lh-property: 0;
  }
  @-webkit-keyframes arm-rotate {
    0% {
      -webkit-transform: rotateZ(-3deg);
    }
    50% {
      -webkit-transform: rotateZ(6deg);
    }
  }
  @-moz-keyframes arm-rotate {
    0% {
      -moz-transform: rotateZ(-3deg);
    }
    50% {
      -moz-transform: rotateZ(6deg);
    }
  }
  @-o-keyframes arm-rotate {
    0% {
      -o-transform: rotateZ(-3deg);
    }
    50% {
      -o-transform: rotateZ(6deg);
    }
  }
  @keyframes arm-rotate {
    0% {
      -webkit-transform: rotateZ(-3deg);
      -moz-transform: rotateZ(-3deg);
      -ms-transform: rotateZ(-3deg);
      transform: rotateZ(-3deg);
    }
    50% {
      -webkit-transform: rotateZ(6deg);
      -moz-transform: rotateZ(6deg);
      -ms-transform: rotateZ(6deg);
      transform: rotateZ(6deg);
    }
  }
  [not-existing] {
    zoom: 1;
  }
  lesshat-selector {
    -lh-property: 0;
  }
  @-webkit-keyframes monkey-breathe {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
    }
    50% {
      -webkit-transform: translate3d(0, 1px, 0);
    }
  }
  @-moz-keyframes monkey-breathe {
    0% {
      -moz-transform: translate3d(0, 0, 0);
    }
    50% {
      -moz-transform: translate3d(0, 1px, 0);
    }
  }
  @-o-keyframes monkey-breathe {
    0% {
      -o-transform: translate3d(0, 0, 0);
    }
    50% {
      -o-transform: translate3d(0, 1px, 0);
    }
  }
  @keyframes monkey-breathe {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    50% {
      -webkit-transform: translate3d(0, 1px, 0);
      -moz-transform: translate3d(0, 1px, 0);
      -ms-transform: translate3d(0, 1px, 0);
      transform: translate3d(0, 1px, 0);
    }
  }
  [not-existing] {
    zoom: 1;
  }
  lesshat-selector {
    -lh-property: 0;
  }
  @-webkit-keyframes levitate {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
    }
    50% {
      -webkit-transform: translate3d(0, 5px, 0);
    }
  }
  @-moz-keyframes levitate {
    0% {
      -moz-transform: translate3d(0, 0, 0);
    }
    50% {
      -moz-transform: translate3d(0, 5px, 0);
    }
  }
  @-o-keyframes levitate {
    0% {
      -o-transform: translate3d(0, 0, 0);
    }
    50% {
      -o-transform: translate3d(0, 5px, 0);
    }
  }
  @keyframes levitate {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    50% {
      -webkit-transform: translate3d(0, 5px, 0);
      -moz-transform: translate3d(0, 5px, 0);
      -ms-transform: translate3d(0, 5px, 0);
      transform: translate3d(0, 5px, 0);
    }
  }
  [not-existing] {
    zoom: 1;
  }
  lesshat-selector {
    -lh-property: 0;
  }
  @-webkit-keyframes star {
    0% {
      -webkit-transform: translate3d(0, 0, 0) rotateZ(0deg);
    }
    50% {
      -webkit-transform: translate3d(0, 5px, 0) rotateZ(180deg);
    }
  }
  @-moz-keyframes star {
    0% {
      -moz-transform: translate3d(0, 0, 0) rotateZ(0deg);
    }
    50% {
      -moz-transform: translate3d(0, 5px, 0) rotateZ(180deg);
    }
  }
  @-o-keyframes star {
    0% {
      -o-transform: translate3d(0, 0, 0) rotateZ(0deg);
    }
    50% {
      -o-transform: translate3d(0, 5px, 0) rotateZ(180deg);
    }
  }
  @keyframes star {
    0% {
      -webkit-transform: translate3d(0, 0, 0) rotateZ(0deg);
      -moz-transform: translate3d(0, 0, 0) rotateZ(0deg);
      -ms-transform: translate3d(0, 0, 0) rotateZ(0deg);
      transform: translate3d(0, 0, 0) rotateZ(0deg);
    }
    50% {
      -webkit-transform: translate3d(0, 5px, 0) rotateZ(180deg);
      -moz-transform: translate3d(0, 5px, 0) rotateZ(180deg);
      -ms-transform: translate3d(0, 5px, 0) rotateZ(180deg);
      transform: translate3d(0, 5px, 0) rotateZ(180deg);
    }
  }
  [not-existing] {
    zoom: 1;
  }
  lesshat-selector {
    -lh-property: 0;
  }
  @-webkit-keyframes blink-l {
    0% {
      -webkit-transform: rotateX(0deg);
    }
    2% {
      -webkit-transform: rotateX(80deg);
    }
    4%,
    20% {
      -webkit-transform: rotateX(0deg);
    }
    22% {
      -webkit-transform: rotateX(80deg);
    }
    24%,
    30% {
      -webkit-transform: rotateX(0deg);
    }
    32% {
      -webkit-transform: rotateX(80deg);
    }
    34%,
    70% {
      -webkit-transform: rotateX(0deg);
    }
    72% {
      -webkit-transform: rotateX(80deg);
    }
    74%,
    100% {
      -webkit-transform: rotateX(0deg);
    }
  }
  @-moz-keyframes blink-l {
    0% {
      -moz-transform: rotateX(0deg);
    }
    2% {
      -moz-transform: rotateX(80deg);
    }
    4%,
    20% {
      -moz-transform: rotateX(0deg);
    }
    22% {
      -moz-transform: rotateX(80deg);
    }
    24%,
    30% {
      -moz-transform: rotateX(0deg);
    }
    32% {
      -moz-transform: rotateX(80deg);
    }
    34%,
    70% {
      -moz-transform: rotateX(0deg);
    }
    72% {
      -moz-transform: rotateX(80deg);
    }
    74%,
    100% {
      -moz-transform: rotateX(0deg);
    }
  }
  @-o-keyframes blink-l {
    0% {
      -o-transform: rotateX(0deg);
    }
    2% {
      -o-transform: rotateX(80deg);
    }
    4%,
    20% {
      -o-transform: rotateX(0deg);
    }
    22% {
      -o-transform: rotateX(80deg);
    }
    24%,
    30% {
      -o-transform: rotateX(0deg);
    }
    32% {
      -o-transform: rotateX(80deg);
    }
    34%,
    70% {
      -o-transform: rotateX(0deg);
    }
    72% {
      -o-transform: rotateX(80deg);
    }
    74%,
    100% {
      -o-transform: rotateX(0deg);
    }
  }
  @keyframes blink-l {
    0% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    2% {
      -webkit-transform: rotateX(80deg);
      -moz-transform: rotateX(80deg);
      -ms-transform: rotateX(80deg);
      transform: rotateX(80deg);
    }
    4%,
    20% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    22% {
      -webkit-transform: rotateX(80deg);
      -moz-transform: rotateX(80deg);
      -ms-transform: rotateX(80deg);
      transform: rotateX(80deg);
    }
    24%,
    30% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    32% {
      -webkit-transform: rotateX(80deg);
      -moz-transform: rotateX(80deg);
      -ms-transform: rotateX(80deg);
      transform: rotateX(80deg);
    }
    34%,
    70% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    72% {
      -webkit-transform: rotateX(80deg);
      -moz-transform: rotateX(80deg);
      -ms-transform: rotateX(80deg);
      transform: rotateX(80deg);
    }
    74%,
    100% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
  }
  [not-existing] {
    zoom: 1;
  }
  lesshat-selector {
    -lh-property: 0;
  }
  @-webkit-keyframes blink-r {
    0% {
      -webkit-transform: rotateX(0deg);
    }
    2% {
      -webkit-transform: rotateX(80deg);
    }
    4%,
    30% {
      -webkit-transform: rotateX(0deg);
    }
    32% {
      -webkit-transform: rotateX(80deg);
    }
    34%,
    50% {
      -webkit-transform: rotateX(0deg);
    }
    52% {
      -webkit-transform: rotateX(80deg);
    }
    54%,
    100% {
      -webkit-transform: rotateX(0deg);
    }
  }
  @-moz-keyframes blink-r {
    0% {
      -moz-transform: rotateX(0deg);
    }
    2% {
      -moz-transform: rotateX(80deg);
    }
    4%,
    30% {
      -moz-transform: rotateX(0deg);
    }
    32% {
      -moz-transform: rotateX(80deg);
    }
    34%,
    50% {
      -moz-transform: rotateX(0deg);
    }
    52% {
      -moz-transform: rotateX(80deg);
    }
    54%,
    100% {
      -moz-transform: rotateX(0deg);
    }
  }
  @-o-keyframes blink-r {
    0% {
      -o-transform: rotateX(0deg);
    }
    2% {
      -o-transform: rotateX(80deg);
    }
    4%,
    30% {
      -o-transform: rotateX(0deg);
    }
    32% {
      -o-transform: rotateX(80deg);
    }
    34%,
    50% {
      -o-transform: rotateX(0deg);
    }
    52% {
      -o-transform: rotateX(80deg);
    }
    54%,
    100% {
      -o-transform: rotateX(0deg);
    }
  }
  @keyframes blink-r {
    0% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    2% {
      -webkit-transform: rotateX(80deg);
      -moz-transform: rotateX(80deg);
      -ms-transform: rotateX(80deg);
      transform: rotateX(80deg);
    }
    4%,
    30% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    32% {
      -webkit-transform: rotateX(80deg);
      -moz-transform: rotateX(80deg);
      -ms-transform: rotateX(80deg);
      transform: rotateX(80deg);
    }
    34%,
    50% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    52% {
      -webkit-transform: rotateX(80deg);
      -moz-transform: rotateX(80deg);
      -ms-transform: rotateX(80deg);
      transform: rotateX(80deg);
    }
    54%,
    100% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
  }
  [not-existing] {
    zoom: 1;
  }
`;
function Error({ global }) {
  return (
    <ErrorPage className={global.theme ? "light" : "dark"}>
      <div id="monkey_404">
        <svg viewBox="0 0 800 480">
          <style type="text/css"></style>
          <path
            id="cloud"
            class="st0"
            d="M658.4,345.2c-10.9,0-19.7-8.8-19.7-19.7c0-10.9,8.8-19.7,19.7-19.7h50.1c9.9-1.5,17.5-10,17.5-20.3
	c0-11.4-9.2-20.6-20.6-20.6v-0.2H633c-11.4,0-20.6-6.7-20.6-18.1c0-11.4,9.2-19.3,20.6-19.3h70.4l2-0.2c7.3-3.1,12.5-11,12.5-19.5
	c0-8.5-4.2-16.7-11.4-19.2l-2.5-0.3h-11.3c-11.9,0-21.6-8.9-21.6-19.9c0-11,9.7-19.9,21.6-19.9h15.8l1.4-0.3
	c8.6-2.5,14.8-10.1,14.8-19.5c0-11.4-9.2-20.6-20.6-20.6h-1.2h-69.2H382.5c-19.8-0.9-19.9-15.9-19.8-17.8c0-0.1,0-0.1,0-0.2
	c0-9.9-8.1-18-18-18h-93.5c-9.9,0-18,8.1-18,18c0,9.4,7.2,17.1,16.3,17.9h9.3c0.2,0,0,0,0.6,0l0.5,0l0.4,0l0.2,0
	c10.1,0.9,18,9.3,18,19.6c0,10.9-8.8,19.7-19.7,19.7h-70.7c-11.3,0-20.5,9.2-20.5,20.6c0,11.3,9.1,20.5,20.4,20.6h48.8
	c10.3,0,18.7,8.4,18.7,18.7c0,10.3-8.4,18.7-18.7,18.7h-23.2c-11.3,0.1-20.4,9.2-20.4,20.6c0,11.3,9.2,20.5,20.5,20.6h6.3
	c10.7,0,19.3,8.7,19.3,19.3c0,10.7-7.8,19.3-18.4,19.3l-1.5,0l-2.8,0.4c-7.3,3.1-11.8,11-11.5,18.9c0.3,8.5,4.2,16.5,11.7,19.6
	c1.1,0.7,3.4,0.9,4.4,0.9h4.5H296h19.7c3.9,0.5,8.2,4.2,7.4,10.4c0,0.4,0,0.8,0.1,1.1c0,0.5-0.1,1-0.1,1.5c0,9.7,7.9,17.5,17.5,17.5
	h60.2c9.7,0,17.5-7.9,17.5-17.5c0-0.4,0-0.8-0.1-1.2c0.1-0.3,0-0.7,0.1-1.1c0.3-6.5,6.4-10.9,10.6-10.8h110.1
	c8.5,0,16.9,6.6,16.9,14.8c0,8.2,6.6,14.8,14.8,14.8h92.6c8.2,0,14.8-6.6,14.8-14.8c0-8.2-6.6-14.8-14.8-14.8 M332.8,187.1h-21.2
	c-11.4,0-20.6-9.2-20.6-20.6c0-11.4,9.2-20.6,20.6-20.6h21.2c11.4,0,20.6,9.2,20.6,20.6C353.3,177.9,344.1,187.1,332.8,187.1z"
          />
          <g id="zelda">
            <path
              id="zelda_dark_shadow"
              class="st0"
              d="M156.9,91.5l-18.5-32l18.5,10.7L156.9,91.5z M193.8,70.2l-18.5-10.7l18.5,32
		L193.8,70.2z M175.4,102.2l-18.5-10.7l18.5,32L175.4,102.2z"
            />
            <path
              id="zelda_light_shadow"
              class="st0"
              d="M175.4,59.5l-18.5,10.7l-18.5-10.7H175.4z M175.4,59.5l18.5,10.7l18.5-10.7H175.4z
		 M156.9,91.5l18.5,10.7l18.5-10.7H156.9z"
            />
            <path
              id="zelda_highlight"
              class="st1"
              d="M150.6,66.6h12.5l-6.3,10.8L150.6,66.6z M193.8,77.4l6.3-10.8h-12.5L193.8,77.4z
		 M175.4,109.4l6.3-10.8h-12.5L175.4,109.4z"
            />
            <path
              id="zelda_stroke"
              class="st2"
              d="M138.4,59.5h36.9l-18.5,32L138.4,59.5z M193.8,91.5l18.5-32h-36.9L193.8,91.5z M175.4,123.5
		l18.5-32h-36.9L175.4,123.5z"
            />
          </g>
          <path
            id="rock_shade"
            class="st0"
            d="M243.3,374.9H140.1c-1.7,0-2.1,2.5-0.4,2.9l44.7,12.6l-19.3,45l34-46.5l39.4-7.4L243.3,374.9z"
          />
          <path
            id="rock"
            class="st5"
            d="M104,367.5h134.6c1.9,3.5,2.3,4.3,4.1,7.8l-4.3,6.3L199,389l-40,52"
          />
          <path
            id="foot_back"
            class="st3"
            d="M187.3,354.5c2.2-4.5,1.6-12.8-3.3-18.5l-9.3,2c2.2,3.5,8.3,7.7,2.3,20.8 c-1.9,4.2-0.8,8.7,4,8.7h22.3c6.5,0,5.3-7.9,2-10.5c-4.2-3.3-10.2-3.6-15.3-1C187.9,357.1,185.3,358.7,187.3,354.5z"
          />
          <path
            id="foot_front"
            class="st4"
            d="M166.3,354.5c2.2-4.5,1.6-12.8-3.3-18.5l-9.3,2c2.2,3.5,8.3,7.7,2.3,20.8 c-1.9,4.2-0.8,8.7,4,8.7h22.3c6.5,0,5.3-7.9,2-10.5c-4.2-3.3-10.2-3.6-15.3-1C166.9,357.1,164.3,358.7,166.3,354.5z"
          />
          <g id="monkey">
            <path
              id="face_1_"
              class="st4"
              d="M213.7,245.2c0,0-6-2.9-11,0.2c-4.6,2.8-9.4,1.7-14,0c-4.6-1.7-16-5.1-19.2,2.6
		c-2,3.8-2.3,9.7,3.8,16.3c-0.9,10.1-2.9,37.9,28.6,34.2c10.1-1.2,24.8-12.7,25.4-18.2s-1.2-6.8-6.4-6.9
		c-1.3-6.5-2.4-12.6-10.8-11.4c-3.9,0.2,7.5,0,8.1-7.5C218.6,247.8,213.7,245.2,213.7,245.2z"
            />
            <path
              id="body"
              class="st4"
              d="M199.8,299.3l9-55.5c0,0-2.1-3.6-7.2-7.1c1.4-1.2,2.2-3.1,1.8-5c-0.6-3.1-3.9-5.3-7.5-4.8
		c-2.9,0.4-5,2.4-5.4,4.8l0,0c-7.2-1.9-16.5-1.9-29.5,1.6c-1.5-3.1-5.6-5.4-9.3-5.7c-5.5-0.4-9.3,3.7-9.7,9.3
		c-0.3,4.4,2.2,8.3,6.1,9.9c-16,25.6-14.6,58.2-11,71.9c4.3,16.1,18.2,21.8,26.3,21.8c13,0,33.8-1.9,37.5-17.7
		C202.9,315,202,303.9,199.8,299.3z"
            />
            <path
              id="tail"
              class="st6"
              d="M89,315c2.2-15.2-23-13.2-21.6,4.8c1.7,22.3,24.4,22.1,42.5,9.1c10.8-7.8,15.3-1.8,19.1,1.1
		c2.3,1.7,6.7,3.3,11-3"
            />
            <path
              id="face"
              class="st7"
              d="M213.7,245.2c0,0-6-2.9-11,0.2c-4.6,2.8-9.4,1.7-14,0c-4.6-1.7-16-5.1-19.2,2.6
		c-2,3.8-2.3,9.7,3.8,16.3c-0.9,10.1-2.9,37.9,28.6,34.2c10.1-1.2,24.8-12.7,25.4-18.2s-1.2-6.8-6.4-6.9
		c-1.3-6.5-2.4-12.6-10.8-11.4c-3.9,0.2,7.5,0,8.1-7.5C218.6,247.8,213.7,245.2,213.7,245.2z"
            />
            <path
              id="mouth"
              class="st8"
              d="M201.6,281.5c0,0,0.2-0.3,0.5-0.8c0.1-0.3,0.4-0.6,0.6-0.9c0.2-0.4,0.6-0.7,0.9-1.1
		c0.4-0.4,0.8-0.8,1.2-1.2c0.5-0.4,0.9-0.8,1.5-1.2c0.5-0.4,1.1-0.7,1.7-1.1c0.6-0.3,1.2-0.6,1.9-0.9c0.6-0.3,1.3-0.5,1.9-0.7
		c0.7-0.2,1.3-0.4,1.9-0.5c0.6-0.2,1.3-0.3,1.8-0.4c0.6-0.1,1.2-0.1,1.7-0.2c0.5-0.1,1-0.1,1.4-0.1c0.4,0,0.8,0,1.1,0c0.6,0,1,0,1,0
		c0.6,0,1,0.5,1,1s-0.5,1-1,1c0,0,0,0,0,0l-0.1,0c0,0-0.3,0-0.9,0c-0.3,0-0.6,0-1,0c-0.4,0-0.8,0-1.3,0c-0.5,0-1,0-1.6,0.1
		c-0.6,0.1-1.1,0.1-1.7,0.3c-0.6,0.1-1.2,0.3-1.8,0.4c-0.6,0.2-1.2,0.4-1.8,0.6c-0.6,0.2-1.2,0.5-1.8,0.8c-0.6,0.3-1.1,0.6-1.6,0.9
		c-0.5,0.3-1,0.7-1.4,1c-0.4,0.3-0.8,0.7-1.2,1c-0.3,0.4-0.7,0.7-0.9,1c-0.2,0.3-0.5,0.5-0.6,0.8c-0.3,0.5-0.5,0.8-0.5,0.8
		c-0.2,0.2-0.5,0.3-0.7,0.1C201.6,282.1,201.5,281.8,201.6,281.5z"
            />
            <path
              id="nose_hole"
              class="st9"
              d="M213.2,266.3c0.6,0,1,0.5,0.9,1.1c0,0.6-0.5,1-1.1,0.9c-0.6,0-1-0.5-0.9-1.1
		C212.1,266.6,212.6,266.2,213.2,266.3z"
            />
            <path
              id="nose_hole_1_"
              class="st9"
              d="M208.1,266.9c0.6,0,1,0.5,0.9,1.1c0,0.6-0.5,1-1.1,0.9c-0.6,0-1-0.5-0.9-1.1
		C207.1,267.3,207.6,266.9,208.1,266.9z"
            />
            <path
              id="eye_right"
              class="st9"
              d="M205,253.5c1.1,0.1,1.9,1,1.9,2.1c-0.1,1.1-1,1.9-2.1,1.9c-1.1-0.1-1.9-1-1.9-2.1
		C203,254.3,203.9,253.4,205,253.5z"
            />
            <path
              id="eye_left"
              class="st9"
              d="M191.5,254.6c1.4,0.1,2.4,1.3,2.3,2.7c-0.1,1.4-1.3,2.4-2.7,2.3c-1.4-0.1-2.4-1.3-2.3-2.7
		C188.9,255.6,190.1,254.5,191.5,254.6z"
            />
            <path
              id="mongkey_shadow_1_"
              class="st0"
              d="M209.1,281c0.9-0.9,9.4-2.6,12-3c2.4-0.4-1.6,4.1-5,5S208.2,282,209.1,281z
		 M143.6,237.1c-0.3,3.6,1.8,7,5.2,8.4c0.4,0.2,0.7,0.5,0.8,0.9c0.1,0.4,0.1,0.9-0.2,1.2c-15.1,24.2-14.7,56.3-10.8,70.8
		c4,15.2,17.1,20.7,24.8,20.7c8.9,0,16.1-1,21.8-2.9c-67.5,2.2-35-81.7-33.3-87.3c0.2-0.8,1.2-4.4,1-5c-0.6-1.6-3.5-0.2-6-4
		c-2.9-4.5,1.2-9.2,2.6-10.6C146.3,230.1,143.9,233,143.6,237.1z M201.7,297.5c7.8-0.9,17.9-8,22.3-13.3
		c-27.4,14.7-44.4,3.1-50.1-9.8c0.3,5.9,1.6,12.6,5.9,17.3C184.4,296.7,191.8,298.7,201.7,297.5z M208.6,261.2
		c-5.7,0.8-8.6-1.1-11.6,1.8c-2.8,2.7-7.7,4.6-3.8,4.1c3.9-0.6,10.1-3.4,16.8-4.1c0,0,0,0,0,0l-0.5,0c-0.2,0-0.3,0-0.4,0
		c-0.5,0-1-0.4-1-0.9C208.2,261.9,208.2,261.5,208.6,261.2z M198.4,300c0-0.1,0-0.1-0.1-0.2c-0.7,0-1.4,0.1-2,0.1
		c-7.8,0-13.9-2.3-18-6.8c-7.7-8.4-6.6-22.5-6.1-28.4c-5.6-6.2-5.6-11.5-4.6-15c-2,2.3-4.8,8.5,2.1,16.1c-3.9,6.4-5.4,26.5,9.2,36.2
		c7.2,4.8,16.6,5.3,20.8,2.8C199.5,302.9,199,301.2,198.4,300z"
            />
            <path
              id="belly"
              class="st10"
              d="M189.1,304c6.2,3,8.1,11.5,5.9,19c-2.3,7.4-9.8,10-16,7c-6.2-3-7.6-10.4-5.3-17.8
		S182.9,301.1,189.1,304z"
            />
            <path
              id="belly_button"
              class="st8"
              d="M191.2,322.3c0-0.1-0.1-0.2-0.2-0.2l-1.9-1.4l1-1.9c0.1-0.1,0.1-0.2,0-0.3
		c-0.1-0.2-0.4-0.4-0.7-0.3c-0.2,0-0.4,0.2-0.5,0.3l-0.9,1.7l-1.6-1.2c-0.2-0.1-0.3-0.1-0.5-0.1c-0.4,0.1-0.5,0.4-0.5,0.6
		c0,0.1,0.1,0.2,0.2,0.2l1.8,1.3l-1.1,2.1c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.3,0.4,0.4,0.7,0.4c0.2,0,0.3-0.1,0.4-0.3l1-1.9l1.7,1.3
		c0.1,0.1,0.3,0.1,0.5,0.1C191.1,322.8,191.3,322.5,191.2,322.3z"
            />
            <g id="monkey_arm">
              <path
                id="arm"
                class="st4"
                d="M164.3,344.1c-0.9-0.3-1.8-0.2-2.5,0.2c-0.3-0.2-0.6-0.3-0.9-0.4c-0.8-0.3-1.5-0.5-2.3-0.5
			c-0.1,0-0.2-0.1-0.3-0.3c-2.4-11.4-1.1-27.6,0.3-43.8c0-0.1,1.2-5.7-2.6-7.2c-5.2-2.1-5.5,2.5-5.5,2.7c-0.5,4.8-3.6,39,1.1,51.4
			c0,0.1,0,0.2,0,0.3c-0.4,0.5-0.7,1-0.9,1.7c-1.5,3.9,0.7,8.3,4.8,9.9c4.1,1.6,8.7-0.3,10.1-4.2c0.5-1.3,0.6-2.7,0.3-4
			c0-0.1,0-0.2,0.1-0.2c0.5-0.7,0.9-1.6,0.5-2.9C166.2,345.5,165.4,344.4,164.3,344.1z"
              />
              <g id="armpit">
                <path
                  id="armpit_mask"
                  class="st11"
                  d="M165,296c0-4.3-1.8-10.8-6-12c-12.5-3.5-12.4,11.1-12.4,11.1s10.8-1.4,16.7,9.6
				C163.3,304.6,165,300.3,165,296z"
                />
                <path
                  id="armpit_line"
                  class="st8"
                  d="M163.9,295.6c0,0-0.3-0.2-0.8-0.5c-0.2-0.2-0.5-0.3-0.9-0.5c-0.3-0.2-0.8-0.4-1.2-0.6
				c-0.5-0.2-0.9-0.5-1.5-0.6c-0.5-0.2-1.1-0.4-1.7-0.6c-0.6-0.1-1.2-0.3-1.9-0.4c-0.7-0.1-1.3-0.2-2-0.2c-0.7,0-1.3,0-2,0.1
				c-0.7,0-1.3,0.2-1.9,0.3c-1.2,0.3-2.4,0.6-3.3,1c-0.9,0.4-1.7,0.8-2.2,1.1c-0.3,0.2-0.5,0.3-0.6,0.4c-0.1,0.1-0.2,0.1-0.2,0.1
				c-0.4,0.3-0.5,0.9-0.2,1.3c0.3,0.5,0.9,0.6,1.4,0.3l0,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.3-0.2,0.5-0.3c0.3-0.2,0.8-0.4,1.3-0.7
				c0.1,0,0.3,0,0.5,0c0.5,0,1.3,0.1,2.2,0.2c0.4,0.1,0.9,0.2,1.4,0.3c0.5,0.1,1,0.3,1.6,0.4c0.5,0.2,1.1,0.4,1.6,0.6
				c0.6,0.2,1.1,0.5,1.6,0.8c0.6,0.3,1,0.6,1.5,1c0.5,0.3,0.9,0.7,1.4,1.1c0.4,0.4,0.8,0.8,1.2,1.1c0.4,0.4,0.7,0.8,1,1.1
				c0.3,0.4,0.6,0.7,0.8,1c0.2,0.3,0.4,0.6,0.6,0.8c0.3,0.5,0.5,0.7,0.5,0.7l0,0c0.1,0.2,0.4,0.3,0.6,0.2c0.2-0.1,0.3-0.4,0.2-0.7
				c0,0-0.2-0.3-0.4-0.8c-0.1-0.3-0.3-0.6-0.5-0.9c-0.2-0.4-0.4-0.7-0.7-1.1c-0.3-0.4-0.6-0.9-0.9-1.3c-0.4-0.4-0.7-0.9-1.2-1.4
				c-0.5-0.4-0.9-0.9-1.4-1.3c-0.5-0.4-1-0.8-1.6-1.2c-0.6-0.4-1.1-0.7-1.8-1c-0.6-0.3-1.2-0.5-1.8-0.8c-0.5-0.2-1-0.3-1.5-0.4
				c0.1,0,0.2,0,0.3-0.1c0.6-0.1,1.2-0.1,1.8-0.2c0.6-0.1,1.2,0,1.8,0c0.6,0,1.2,0.2,1.7,0.2c0.6,0.1,1.1,0.3,1.6,0.4
				c0.5,0.1,1,0.3,1.4,0.5c0.4,0.2,0.8,0.3,1.2,0.5c0.3,0.2,0.6,0.3,0.9,0.4c0.5,0.2,0.8,0.4,0.8,0.4l0,0c0.2,0.1,0.5,0,0.6-0.2
				C164.2,296,164.1,295.7,163.9,295.6z"
                />
              </g>
            </g>
          </g>
          <g id="tetris">
            <path
              id="tetris_stroke"
              class="st12"
              d="M487.5,357.5h30v30h-30V357.5z"
            />
            <path
              class="st0"
              d="M516,359l-4.5,5.4c-0.3,0.4-0.5,0.8-0.5,1.3V380c0,0.6-0.4,1-1,1h-14.3c-0.5,0-0.9,0.2-1.3,0.5L489,386h27V359
		z"
            />
            <path
              id="tetris_stroke_2_"
              class="st12"
              d="M487.5,327h30v30h-30V327z"
            />
            <path
              class="st0"
              d="M516,328.5l-4.5,5.4c-0.3,0.4-0.5,0.8-0.5,1.3v14.3c0,0.6-0.4,1-1,1h-14.3c-0.5,0-0.9,0.2-1.3,0.5l-5.4,4.5h27
		V328.5z"
            />
            <path
              id="tetris_stroke_1_"
              class="st12"
              d="M518,357.5h30v30h-30V357.5z"
            />
            <path
              class="st0"
              d="M546.5,359l-4.5,5.4c-0.3,0.4-0.5,0.8-0.5,1.3V380c0,0.6-0.4,1-1,1h-14.3c-0.5,0-0.9,0.2-1.3,0.5l-5.4,4.5h27
		V359z"
            />
            <path
              id="tetris_stroke_3_"
              class="st12"
              d="M549,357.5h30v30h-30V357.5z"
            />
            <path
              class="st0"
              d="M577.5,359l-4.5,5.4c-0.3,0.4-0.5,0.8-0.5,1.3V380c0,0.6-0.4,1-1,1h-14.3c-0.5,0-0.9,0.2-1.3,0.5l-5.4,4.5h27
		V359z"
            />
            <path
              id="tetris_stroke_4_"
              class="st12"
              d="M549,388h30v30h-30V388z"
            />
            <path
              class="st0"
              d="M577.5,389.5l-4.5,5.4c-0.3,0.4-0.5,0.8-0.5,1.3v14.3c0,0.6-0.4,1-1,1h-14.3c-0.5,0-0.9,0.2-1.3,0.5l-5.4,4.5
		h27V389.5z"
            />
          </g>
          <g id="stars">
            <path
              id="star_a"
              class="st4"
              d="M652.6,332.5c-5.3,3.1-12.1,1.2-15.1-4.1l-1.4-2.4l1.4,2.4c3.1,5.3,1.2,12.1-4.1,15.1l-2.4,1.4
		l2.4-1.4c5.3-3.1,12.1-1.2,15.1,4.1l1.4,2.4l-1.4-2.4C645.5,342.3,647.3,335.5,652.6,332.5l2.4-1.4L652.6,332.5z"
            />
            <path
              id="star_b"
              class="st4"
              d="M503.4,73.7c-8,4.6-18.1,1.9-22.7-6.1l-2.1-3.6l2.1,3.6c4.6,8,1.9,18.1-6.1,22.7l-3.6,2.1l3.6-2.1
		c8-4.6,18.1-1.9,22.7,6.1l2.1,3.6l-2.1-3.6C492.7,88.4,495.4,78.3,503.4,73.7l3.6-2.1L503.4,73.7z"
            />
            <path
              id="star_c"
              class="st4"
              d="M330.4,335.7c-8,4.6-18.1,1.9-22.7-6.1l-2.1-3.6l2.1,3.6c4.6,8,1.9,18.1-6.1,22.7l-3.6,2.1
		l3.6-2.1c8-4.6,18.1-1.9,22.7,6.1l2.1,3.6l-2.1-3.6C319.7,350.4,322.4,340.3,330.4,335.7l3.6-2.1L330.4,335.7z"
            />
            <path
              id="star_d"
              class="st4"
              d="M135.6,176.5c-5.3,3.1-12.1,1.2-15.1-4.1l-1.4-2.4l1.4,2.4c3.1,5.3,1.2,12.1-4.1,15.1l-2.4,1.4
		l2.4-1.4c5.3-3.1,12.1-1.2,15.1,4.1l1.4,2.4l-1.4-2.4C128.5,186.3,130.3,179.5,135.6,176.5l2.4-1.4L135.6,176.5z"
            />
          </g>
          <g id="moon">
            <path
              id="moon_body"
              class="st4"
              d="M641,34c26,0,47,21,47,47s-21,47-47,47s-47-21-47-47S615,34,641,34z"
            />
            <path
              id="moon_shades"
              class="st0"
              d="M622.5,55.9c1.3,2.3,0,5.8-3.1,7.7c-3,2-6.6,1.7-7.9-0.6c-1.3-2.3,0-5.8,3.1-7.7
		C617.6,53.3,621.1,53.6,622.5,55.9z M628.8,94.1c-4.1-6.1-11.6-9-16.7-6.4c-5.1,2.6-5.9,9.6-1.7,15.7c4.1,6.1,11.6,9,16.7,6.4
		C632.2,107.2,632.9,100.2,628.8,94.1z M644.5,109c-3.6,0-6.5,2.2-6.5,5s2.9,5,6.5,5s6.5-2.2,6.5-5S648.1,109,644.5,109z
		 M645.7,95.8c-2.3-1.2-5-0.5-6,1.4c-1,2,0,4.5,2.3,5.7c2.3,1.2,5,0.5,6-1.4C649,99.6,648,97,645.7,95.8z M686.5,81
		c0-25.1-20.4-45.5-45.5-45.5c-16.1,0-30.2,8.4-38.3,21c7.9-5.9,17.7-9.5,28.3-9.5c26,0,47,21,47,47c0,6.3-1.3,12.3-3.5,17.8
		C681.9,103.6,686.5,92.8,686.5,81z"
            />
          </g>
          <g id="number_4">
            <path
              id="number_4_outline"
              class="st13"
              d="M379.5,235.5c0-4.9-3.9-9.1-8.7-9.1h-11.4v-72.5c0-9.1-8.5-15.7-17.6-15
		c-6,0-11.8,3.1-15.1,8l-52.7,79.8c-1.2,2.1-2.1,4.5-2.1,6.6c0,6.6,5,11.1,10.3,11.1H339v24.3c0,5.6,4.3,10.1,9.9,10.1
		c6,0,10.5-4.5,10.5-10.1v-24.3h11.4C375.6,244.3,379.5,240.4,379.5,235.5z M339,226.4h-45.5l45.5-67.8V226.4z"
            />
            <path
              id="number_4_inner_lines"
              class="st14"
              d="M349,158v109.2 M345.9,147c-5.6,0-10.9,2.8-14,7.2l-47.1,69.5
		c-1.2,1.9-3.3,4.3-3.6,5.8c-0.8,4.6,2.3,5.5,7.3,5.5H340 M359.5,235H379 M342,232l-4,7 M345,232l-4,7 M358,232l-4,7 M361,232l-4,7"
            />
            <path
              id="number_4_dots"
              class="st9"
              d="M349,266c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9
		C346.1,267.3,347.4,266,349,266z M349,155.1c1.6,0,2.9,1.3,2.9,2.9s-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9S347.4,155.1,349,155.1
		z M344.4,144.6c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9C341.4,145.9,342.7,144.6,344.4,144.6z"
            />
          </g>
          <g id="number_4_2">
            <path
              id="number_4_outline_2"
              class="st13"
              d="M627,235.5c0-4.9-3.9-9.1-8.7-9.1h-11.4v-72.5c0-9.1-8.5-15.7-17.6-15
		c-6,0-11.8,3.1-15.1,8l-52.7,79.8c-1.2,2.1-2.1,4.5-2.1,6.6c0,6.6,5,11.1,10.3,11.1h56.7v24.3c0,5.6,4.3,10.1,9.9,10.1
		c6,0,10.5-4.5,10.5-10.1v-24.3h11.4C623.1,244.3,627,240.4,627,235.5z M586.5,226.4H541l45.5-67.8V226.4z"
            />
            <path
              id="number_4_inner_lines_2"
              class="st14"
              d="M596.5,158v109.2 M593.3,147c-5.6,0-10.9,2.8-14,7.2l-47.1,69.5
		c-1.2,1.9-3.3,4.3-3.6,5.8c-0.8,4.6,2.3,5.5,7.3,5.5h51.5 M607,235h19.5 M589.5,232l-4,7 M592.5,232l-4,7 M605.5,232l-4,7
		 M608.5,232l-4,7"
            />
            <path
              id="number_4_dots_2"
              class="st9"
              d="M596.5,266c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9
		C593.6,267.3,594.9,266,596.5,266z M596.5,155.1c1.6,0,2.9,1.3,2.9,2.9s-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9
		S594.9,155.1,596.5,155.1z M591.8,144.6c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9
		C588.9,145.9,590.2,144.6,591.8,144.6z"
            />
          </g>
          <g id="number_0">
            <path
              id="number_0_outline"
              class="st13"
              d="M502,208.9c0-34-15.9-70.9-54-70.9c-38.3,0-54,36.9-54,70.9s15.7,71.1,54,71.1
		C486.1,280,502,242.9,502,208.9z M481.1,208.9c0,26.8-8.7,53-33.1,53c-24.6,0-33.1-26.2-33.1-53c0-26.8,8.5-52.8,33.1-52.8
		C472.4,156.1,481.1,182.1,481.1,208.9z"
            />
            <path
              id="number_0_inner_lines"
              class="st15"
              d="M487.2,175.7c-6.7-16.8-19.3-29.4-39.2-29.4c-32,0-45.1,32.5-45.1,62.4
		s13.1,62.6,45.1,62.6c31.8,0,44.1-32.6,44.1-62.6 M487.5,172c3,0,5.5,2.5,5.5,5.5c0,3-2.5,5.5-5.5,5.5c-3,0-5.5-2.5-5.5-5.5
		C482,174.5,484.5,172,487.5,172z M492.5,202c3,0,5.5,2.5,5.5,5.5c0,3-2.5,5.5-5.5,5.5c-3,0-5.5-2.5-5.5-5.5
		C487,204.5,489.5,202,492.5,202z"
            />
            <path
              id="number_0_dots"
              class="st9"
              d="M492.5,205c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5
		C490,206.1,491.1,205,492.5,205z M487.5,175c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5
		C485,176.1,486.1,175,487.5,175z M448.1,143.4c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9
		C445.1,144.7,446.4,143.4,448.1,143.4z M448.1,268.3c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9
		C445.1,269.6,446.4,268.3,448.1,268.3z"
            />
          </g>
          <g id="sword">
            <path
              id="sword_handle"
              class="st4"
              d="M444.6,196.6l0.6-0.8c1.5-2,1.8-4.3,3.8-2.8l8.9,6.8c2,1.5,2.4,4.3,0.9,6.3l-0.6,0.8
		c-1.5,2-4.3,2.4-6.3,0.9L443,201C441,199.5,443.1,198.5,444.6,196.6z"
            />
            <path
              id="sword_handle_line"
              class="st14"
              d="M453.9,197c2,1.5,2.4,4.3,0.9,6.3l-0.6,0.8c-1.5,2-4.3,2.4-6.3,0.9"
            />
            <path
              id="sword_hilt"
              class="st4"
              d="M432.5,197.1l10.6-13.9c1.6-2.2,4.7-2.6,6.9-0.9c2.2,1.6,2.6,4.7,0.9,6.9l-10.6,13.9
		c-1.6,2.2-4.7,2.6-6.9,0.9C431.2,202.4,430.8,199.3,432.5,197.1z"
            />
            <polygon
              id="sword_blade"
              class="st13"
              points="437,199 446,187.3 387.3,138.9 366.3,136.7 372.2,154 	"
            />
            <polygon
              id="sword_blade_shadow"
              class="st0"
              points="436.7,197 440.3,192.3 369,138.5 368.5,138.4 373.5,153 	"
            />
          </g>
        </svg>
      </div>
    </ErrorPage>
  );
}

const mapStateToProps = (state) => ({
  global: state.global,
});

export default connect(mapStateToProps, null)(Error);
