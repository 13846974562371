import React, { Component } from "react";
import { connect } from "react-redux";
import { getActivity } from "../../redux/actions/activityActions";
import Table from "../../components/Table";
import Moment from "react-moment";
import Card from "../../components/Card";
class Activity extends Component {
  render() {
    const { activities, data, loading } = this.props.activity;
    const columns = [
      {
        id: "title",
        Header: "Title",
        accessor: (d) => d._id,
        Cell: (props) => (
          <React.Fragment>{props.original.title}</React.Fragment>
        ),
      },
      {
        id: "date",
        Header: "Date",
        accessor: (d) => d._id,
        Cell: (props) => (
          <Moment format="DD-MM-YYYY HH:mm">{props.original.createdAt}</Moment>
        ),
      },
    ];
    return (
      <Card title="Activity">
        <Table
          data={activities}
          columns={columns}
          loading={loading}
          page={!data.pagination ? 0 : data.pagination.current - 1}
          pages={!data.pagination ? 1 : data.pagination.total}
          onFetchData={(state) => {
            let query = `?page=${state.page + 1}&user=${this.props.userId}`;
            this.props.getActivity(query);
          }}
          noDataText="No activity"
          minRows={10}
        />
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  activity: state.activity,
});

export default connect(mapStateToProps, { getActivity })(Activity);
