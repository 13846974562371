import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Header from "../Modal/Header";
import Actions from "../Modal/Actions";
import Content from "../Modal/Content";
import ImageUpload from "../ImageUpload";
import GlobalLoader from "../GlobalLoader";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { addGift } from "../../redux/actions/giftsActions";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    outline: "none",
    minWidth: 500,
  },
}));

function AddGift(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [picture, setPicture] = React.useState(null);
  const [picturePreview, setPicturePreview] = React.useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onPictureChange = (event) => {
    setPicture(event.target.files[0]);
    let reader = new FileReader();

    reader.onloadend = () => {
      setPicturePreview(reader.result);
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  const _handleSubmit = (values, formikApi) => {
    const formData = new FormData();
    formData.append("file", picture);
    formData.append("title", values.title);
    formData.append("coins", values.coins);

    props.addGift(formData, formikApi).then((data) => {
      handleClose();
    });
  };

  return (
    <React.Fragment>
      <Button color="primary" variant="contained" onClick={handleOpen}>
        ADD NEW
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={{
                title: "",
                coins: "",
              }}
              validationSchema={Yup.object().shape({
                title: Yup.string().required("Required"),
                coins: Yup.number()
                  .required("Required")
                  .typeError("Please enter a number"),
              })}
              onSubmit={_handleSubmit}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  {isSubmitting && <GlobalLoader></GlobalLoader>}
                  <Header title="Add new gift" />
                  <Content>
                    <ImageUpload
                      onImageChange={onPictureChange}
                      imagePreviewUrl={picturePreview}
                    />
                    <Field
                      component={TextField}
                      type="text"
                      margin="normal"
                      fullWidth
                      id="title"
                      label="Title "
                      name="title"
                      variant="outlined"
                    />
                    <Field
                      component={TextField}
                      margin="normal"
                      fullWidth
                      name="coins"
                      label="Price in coins "
                      type="text"
                      id="coins"
                      variant="outlined"
                    />
                  </Content>
                  <Actions>
                    <Button
                      variant="contained"
                      onClick={handleClose}
                      style={{ marginRight: 15 }}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                  </Actions>
                </Form>
              )}
            </Formik>
          </Paper>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}

export default connect((state) => state, { addGift })(AddGift);
