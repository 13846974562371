import React from "react";
import useSWR from "swr";
import axios from "axios";
import { API } from "../../config";
import { connect } from "react-redux";

import styled from "styled-components";

const Active = styled.div`
  border: 1px solid ${(props) => props.theme.palette.divider};
  color: ${(props) => props.theme.palette.text.primary};
  padding: 6px 12px;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;
`;

const Asa = styled.div`
  border: 1px solid ${(props) => props.theme.palette.divider};
  color: ${(props) => props.theme.palette.text.primary};
  padding: 6px 12px;
  border-radius: 3px;
  margin-right: 15px;
  cursor: pointer;
`;

const Waiting = styled.div`
  border: 1px solid ${(props) => props.theme.palette.divider};
  color: ${(props) => props.theme.palette.text.primary};
  padding: 6px 12px;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;
`;

function ChatterInfo({ auth }) {
  const { data, error } = useSWR(
    `${API}/info/chatter-info/${auth.user._id}`,
    axios
  );

  if (error) return <div></div>;
  if (!data) return <div></div>;
  const info = data.data;
  return (
    <React.Fragment>
      <Active>Active Dialogues: {info.active}</Active>
      <Asa>Asa: {info.asa}</Asa>
      <Waiting> In waiting Room: {info.waiting}</Waiting>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(ChatterInfo);
