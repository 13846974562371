import React from "react";
import Head from "../../components/Team/Head";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import useSWR from "swr";
import axios from "axios";
import { API } from "../../config";
import Loader from "../../components/LoaderPage";
import Users from "./usersDashbaord";
function Card({ number, title }) {
  return (
    <Paper style={{ padding: 30, textAlign: "center", height: "100%" }}>
      <h1>{number}</h1>
      <p style={{ fontSize: 18 }}>{title}</p>
    </Paper>
  );
}

function Advertiser(props) {
  const advertiserId = props.match.params.id;
  const { data, error } = useSWR(
    `${API}/info/full-advertiser/${advertiserId}`,
    axios
  );
  if (error) return <div></div>;
  if (!data) return <Loader />;
  const user = data.data.user;

  return (
    <React.Fragment>
      <Head user={user} />
      <Grid container spacing={2} style={{ marginTop: 15 }}>
        <Grid item xs={12} md={3}>
          <Card number={data.data.total} title="Total registered users" />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            number={data.data.thisMonth}
            title="Total registered users this month"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Card
            number={data.data.totalWithPayments}
            title="Total that have done payments"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            number={data.data.thisMonthWithPayments}
            title="Total this month with payments"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            number={data.data.payments.amount.toFixed(2) + "€"}
            title="Total payments amount this month"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            number={data.data.payments.done}
            title="Total payments this month"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Card number={data.data.totalOnline} title="Total online clients" />
        </Grid>
      </Grid>
      <h1>Registered users</h1>
      <Users refId={user.refId} />
    </React.Fragment>
  );
}

export default Advertiser;
