import axios from "axios";
import { GET_ORDERS, ORDERS_LOADING } from "../types";
import { API } from "../../config";
import { returnErrors } from "./errorActions";
import { tokenConfig } from "./authActions";
export const getOrders = (query) => (dispatch, getState) => {
  dispatch(setOrdersLoading());
  axios
    .get(`${API}/orders${query}`, tokenConfig(getState))
    .then((res) =>
      dispatch({
        type: GET_ORDERS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const setOrdersLoading = () => {
  return {
    type: ORDERS_LOADING,
  };
};
