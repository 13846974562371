/* eslint-disable no-extend-native */
import React from "react";
import styled from "styled-components";
import { Picker } from "emoji-mart";
import MoodIcon from "@material-ui/icons/Mood";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import SendIcon from "@material-ui/icons/Send";
import { connect } from "react-redux";
import { sendMessage } from "../../../redux/actions/messengerActions";
import { addOuts } from "../../../redux/actions/authActions";
import $ from "jquery";
import cogoToast from "cogo-toast";
const InputWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  padding: 15px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
  display: flex;
  .message {
    padding: 0 14px;
    border: none;
    background: ${(props) => props.theme.palette.divider};
    border-radius: 50px;
    outline: none;
    min-height: 50px;
    line-height: 50px;
    width: 100%;
    margin-right: 20px;
    color: ${(props) => props.theme.palette.text.primary};
  }
  .input {
    width: 100%;
    position: relative;
  }
  form {
    width: 100%;
  }
  .send--message {
    position: absolute;
    top: 0;
    right: 5px;
  }
`;

const Info = styled.div`
  border-top: 1px solid ${(props) => props.theme.palette.divider};
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  span {
    line-height: 30px;
  }
`;
function MessageInput(props) {
  const [open, setOpen] = React.useState(false);
  const [message, SetMessage] = React.useState("");
  const [typing, setTyping] = React.useState(false);
  const { user } = props.auth;
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      member: props.member._id,
      user: props.profile._id,
      body: message,
      chatterId: user._id,
      channelId: props.channelId,
      type: 2,
    };

    if (message.length < 70) {
      const left = 70 - str.length;

      cogoToast.error(
        `${
          left < 0 ? 0 : left
        } more characters before you can send this message!`,
        {
          position: "top-right",
        }
      );
    } else {
      props.onSubmit(data);
      SetMessage("");

      $("#messages").animate(
        { scrollTop: $("#messages").prop("scrollHeight") },
        200
      );
    }
  };

  const setEmoji = (emoji) => {
    const inputCursor = document.getElementById("message").selectionStart;
    String.prototype.splice = function (idx, rem, str) {
      return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
    };
    var result = message.splice(inputCursor, 0, emoji);
    SetMessage(result);
  };

  const str = message.replace(/ +(?= )/g, "");

  const left = 70 - str.length;
  return (
    <React.Fragment>
      <Info>
        <span>Minimum 70 characters</span>{" "}
        <span>
          <strong>Written: </strong>
          {str.length} - <strong>Left: </strong> {left < 0 ? 0 : left}
        </span>
      </Info>
      <InputWrapper>
        <form onSubmit={onSubmit} className="input">
          <input
            value={str}
            type="text"
            onChange={(event) => SetMessage(event.target.value)}
            id="message"
            className="message"
            placeholder="Enter your message..."
            autoComplete="off"
            style={{ paddingRight: 70 }}
          />
          {str.length > 70 && (
            <IconButton className="send--message" color="primary" type="submit">
              <SendIcon />
            </IconButton>
          )}
        </form>

        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <IconButton
              color="primary"
              component="span"
              onClick={handleClick}
              style={{ marginLeft: 15 }}
            >
              <MoodIcon />
            </IconButton>

            {open && (
              <Picker
                onSelect={(emoji) => setEmoji(emoji.native)}
                showSkinTones={false}
                showPreview={false}
                darkMode={false}
                set="apple"
                style={{ position: "absolute", bottom: "100px", right: "20px" }}
              />
            )}
          </div>
        </ClickAwayListener>
      </InputWrapper>
    </React.Fragment>
  );
}

export default connect((state) => state, {
  sendMessage,
  addOuts,
})(MessageInput);
