import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import { API } from "../../config";
export default function VerifyEmail({ userId }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleVerify = () => {
    axios
      .post(`${API}/users/verify/${userId}`, { emailVerified: true })
      .then((res) => {
        setOpen(false);
        window.location.reload();
      });
  };
  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Verify
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Verifica l'e-mail dell'utente</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Verifying user email will allow him to signin without requiring him
            to verify email by itself. Also this will charge the user with 20
            coins!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button
            onClick={handleVerify}
            color="primary"
            variant="contained"
            autoFocus
          >
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
