import React from "react";
import { Switch } from "react-router-dom";
import Default from "./Routes/default";
import Canvas from "./Routes/canvas";
import Error from "../views/Error";
import Dashboard from "../views/Dashboard";
import Login from "../views/Login";
import Clients from "../views/Clients";
import Profiles from "../views/Profiles";
import Admins from "../views/Admins";
import Team from "../views/Team";
import TeamMember from "../views/team/index";
import EditUser from "../views/user/edit";
import Gifts from "../views/Gifts";
import Coins from "../views/Coins";
import Coupons from "../views/Coupons";
import WaitingRoom from "../views/WaitingRoom";
import Favorites from "../views/Favorites";
import Tickets from "../views/Tickets";
import EditProfile from "../views/profiles/edit";
import Client from "../views/client/index";
import Profile from "../views/profiles/index";
import Messenger from "../views/Messenger";
import Chat from "../views/Chat";
import Advertising from "../views/Advertising";
import AdvertiserUsers from "../views/advertiser/Users";
import Dialogues from "../views/admin/Dialogues";
import Advertiser from "../views/advertiser/index";
import Payments from "../views/Payments";
import Asa from "../views/Asa";
import GlobalRoom from "../views/GlobalRoom";
export default function SwitchWrapper({ location, setTheme, theme }) {
  return (
    <Switch location={location}>
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/"
        component={Dashboard}
      />

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/admins"
        component={Admins}
      />

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/clients"
        component={Clients}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/profiles"
        component={Profiles}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/chatters"
        component={Team}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/team/:id"
        component={TeamMember}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/users/edit/:id"
        component={EditUser}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/profiles/edit/:id"
        component={EditProfile}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/gifts"
        component={Gifts}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/coins"
        component={Coins}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/coupons"
        component={Coupons}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/waiting-room"
        component={WaitingRoom}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/favorites"
        component={Favorites}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/tickets"
        component={Tickets}
      />

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/client/:id"
        component={Client}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/profile/:id"
        component={Profile}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/messenger"
        component={Messenger}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/messenger/:id"
        component={Chat}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/advertising"
        component={Advertising}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/advertiser/users"
        component={AdvertiserUsers}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/admin/dialogues"
        component={Dialogues}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/advertiser/:id"
        component={Advertiser}
      />

      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/payments"
        component={Payments}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/asa"
        component={Asa}
      />
      <Default
        setTheme={setTheme}
        theme={theme}
        exact={true}
        path="/global-room"
        component={GlobalRoom}
      />
      <Canvas exact={true} path="/login" component={Login} />
      <Default setTheme={setTheme} theme={theme} path="*" component={Error} />
    </Switch>
  );
}
