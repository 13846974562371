import React from "react";
import { connect } from "react-redux";
import { getUsers } from "../redux/actions/usersActions";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import Header from "../components/Page/Header";
import Moment from "react-moment";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import AddNew from "../components/Team/AddNew";
import ChangePassword from "../components/User/ChangePassword";
import Delete from "../components/User/Delete";

function Team(props) {
  const { users, data, loading } = props.users;
  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Link to={`/team/${props.original._id}`}>
          <strong>
            {props.original.profile ? props.original.profile.name : ""}
          </strong>
        </Link>
      ),
    },
    {
      id: "email",
      Header: "E-mail",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <a
            style={{ color: "inherit", textTransform: "lowercase" }}
            href={`mailto:${props.original.email} `}
          >
            {props.original.email}{" "}
          </a>
        </strong>
      ),
    },

    {
      id: "phone",
      Header: "Phone",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <a
            style={{ color: "inherit", textTransform: "lowercase" }}
            href={`tel:+${props.original.phone} `}
          >
            {props.original.phone}
          </a>
        </strong>
      ),
    },
    {
      id: "createdAt",
      Header: "Date",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <Moment>{props.original.createdAt}</Moment>
        </strong>
      ),
    },
    {
      id: "actions",
      Header: "Actions ",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <Tooltip arrow title="Edit this user">
            <ChangePassword userId={props.original._id} />
          </Tooltip>
        </Actions>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Header title="Team" size={data.total}>
        <AddNew />
      </Header>
      <Table
        data={users}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&type=3&${queryString}`;
          props.getUsers(query);
        }}
        noDataText="No team users"
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  users: state.users,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { getUsers })(Team);
