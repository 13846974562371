import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Actions from "../Modal/Actions";
import Header from "../Modal/Header";
import Content from "../Modal/Content";
import { connect } from "react-redux";
import { disableUser } from "../../redux/actions/usersActions";
import BlockIcon from "@material-ui/icons/Block";
function DeleteUser(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const data = {
      deletedAdmin: true,
    };
    props.disableUser(props.userId, data);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <span className="fi" onClick={handleClickOpen}>
        <BlockIcon></BlockIcon>
      </span>
      <Dialog open={open} onClose={handleClose}>
        <Header title="Delete User" />
        <Content>Delete this user info from advertisers</Content>
        <Actions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Delete
          </Button>
        </Actions>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { disableUser })(DeleteUser);
