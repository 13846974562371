import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import useSWR from "swr";
import axios from "axios";
import { API } from "../../config";
import Loader from "../LoaderPage";
function Card({ number, title }) {
  return (
    <Paper style={{ padding: 30, textAlign: "center", height: "100%" }}>
      <h1>{number}</h1>
      <p style={{ fontSize: 18 }}>{title}</p>
    </Paper>
  );
}

function Advertiser(props) {
  const { user } = props.auth;
  const { data, error } = useSWR(`${API}/info/chatter/${user._id}`, axios);
  if (error) return <div></div>;
  if (!data) return <Loader />;
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card number={data.data.totalOuts} title="Total outs" />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            number={data.data.outsThisMonth}
            title="Total outs this month"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card number={data.data.totalIns} title="Total ins" />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            number={data.data.totalInsThisMonth}
            title="Total ins this month"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            number={data.data.activeDialogues}
            title="Total active dialogues"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Advertiser);
