import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Actions from "../Modal/Actions";
import Header from "../Modal/Header";
import Content from "../Modal/Content";
import { connect } from "react-redux";
import { updatePassword } from "../../redux/actions/usersActions";
import LockIcon from "@material-ui/icons/Lock";
import GlobalLoader from "../GlobalLoader";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
function ChangePassword(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _handleSubmit = (values, formikApi) => {
    const { password, confirmPassword } = values;
    const data = {
      password,
      confirmPassword,
    };
    props.updatePassword(props.userId, data, formikApi);
  };

  return (
    <React.Fragment>
      <span className="fi" onClick={handleClickOpen}>
        <LockIcon></LockIcon>
      </span>
      <Dialog open={open} onClose={handleClose}>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().required("Required"),
            confirmPassword: Yup.string().required("Required"),
          })}
          onSubmit={_handleSubmit}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              {isSubmitting && <GlobalLoader></GlobalLoader>}
              <Header title="Change password" />
              <Content>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="password"
                      margin="normal"
                      fullWidth
                      id="password"
                      label="Password "
                      name="password"
                      variant="outlined"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      type="password"
                      margin="normal"
                      fullWidth
                      id="confirmPassword"
                      label="Confirm Password"
                      name="confirmPassword"
                      variant="outlined"
                      style={{ margin: 0 }}
                    />
                  </Grid>
                </Grid>
              </Content>
              <Actions>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  style={{ marginRight: 15 }}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Update
                </Button>
              </Actions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { updatePassword })(ChangePassword);
