import axios from "axios";
import {
  GET_ACTIVITY,
  ACTIVITY_LOADING,
  GET_COINS_ACTIVITY,
  COINS_ACTIVITY_LOADING
} from "../types";
import { API } from "../../config";
import { returnErrors } from "./errorActions";

export const getActivity = (query) => (dispatch) => {
  dispatch(setActivityLoading());
  axios
    .get(`${API}/activity${query}`)
    .then((res) =>
      dispatch({
        type: GET_ACTIVITY,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};


export const setActivityLoading = () => {
  return {
    type: ACTIVITY_LOADING,
  };
};


export const getCoinsActivity = (query) => (dispatch) => {
  dispatch(setActivityLoading());
  axios
    .get(`${API}/activity/coins/${query}`)
    .then((res) =>
      dispatch({
        type: GET_COINS_ACTIVITY,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};


export const setCoinsActivityLoading = () => {
  return {
    type: COINS_ACTIVITY_LOADING,
  };
};
