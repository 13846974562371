import React, { Component } from "react";
import { connect } from "react-redux";
import { getUsers } from "../redux/actions/usersActions";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import Header from "../components/Page/Header";
import Moment from "react-moment";
import Tooltip from "@material-ui/core/Tooltip";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Delete from "../components/User/Delete";
import New from "../components/Profiles/New";
class Team extends Component {
  static propTypes = {
    users: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool,
  };
  render() {
    const { users, data, loading } = this.props.users;
    const columns = [
      {
        id: "picture",
        Header: "Bild",
        accessor: (d) => d._id,
        Cell: (props) => (
          <Link to={`/profile/${props.original._id}`}>
            <Avatar
              src={props.original.profile ? props.original.profile.picture : ""}
              alt={props.original.profile ? props.original.profile.name : ""}
            />
          </Link>
        ),
      },
      {
        id: "username",
        Header: "Firstname",
        filterable: true,
        accessor: (d) => d._id,
        Cell: (props) => (
          <Link to={`/profile/${props.original._id}`}>
            <strong>{props.original.username}</strong>
          </Link>
        ),
      },
      {
        id: "email",
        Header: "E-mail",
        filterable: true,
        accessor: (d) => d._id,
        Cell: (props) => (
          <strong>
            <a
              style={{ color: "inherit", textTransform: "lowercase" }}
              href={`mailto:${props.original.email} `}
            >
              {props.original.email}{" "}
            </a>
          </strong>
        ),
      },

      {
        id: "createdAt",
        Header: "Date",
        accessor: (d) => d._id,
        Cell: (props) => (
          <strong>
            <Moment>{props.original.createdAt}</Moment>
          </strong>
        ),
      },
      {
        id: "actions",
        Header: "Actions ",
        accessor: (d) => d._id,
        Cell: (props) => (
          <Actions>
            <Tooltip arrow title="Edit this profile">
              <Link to={`/profiles/edit/${props.original._id}`}>
                <FiEdit></FiEdit>
              </Link>
            </Tooltip>
            {/* <Tooltip arrow title='Delete this profile'>
              <Delete userId={props.original._id} />
            </Tooltip> */}
          </Actions>
        ),
      },
    ];

    return (
      <React.Fragment>
        <Header title="Profile" size={data.total}>
          <New />
        </Header>
        <Table
          data={users}
          columns={columns}
          loading={loading}
          key={1}
          page={!data.pagination ? 0 : data.pagination.current - 1}
          pages={!data.pagination ? 1 : data.pagination.total}
          onFetchData={(state) => {
            var queryString = state.filtered
              .map((key) => key.id + "=" + key.value)
              .join("&");
            let query = `?page=${state.page + 1}&type=2&${queryString}`;
            this.props.getUsers(query);
          }}
          noDataText="No profiles"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { getUsers })(Team);
