import axios from "axios";
import {
  GET_CHANNELS,
  CHANNELS_LOADING,
  ADD_MESSAGE,
  GET_MESSAGES,
  ADD_OUTS,
  MESSAGES_LOADING,
} from "../types";
import cogoToast from "cogo-toast";
import { tokenConfig } from "./authActions";
import { returnErrors } from "./errorActions";
import { API } from "../../config";
export const getChannels = (query) => (dispatch, getState) => {
  dispatch(setChannelsLoading());
  axios
    .get(`${API}/channels${query}`, tokenConfig(getState))
    .then((res) =>
      dispatch({
        type: GET_CHANNELS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const checkChannel = (query) => (dispatch) => {
  dispatch(setChannelsLoading());
  axios
    .get(`${API}/channels${query}`)
    .then((res) =>
      dispatch({
        type: GET_CHANNELS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const sendMessage = (data) => (dispatch, getState) => {
  axios
    .post(`${API}/messages`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_MESSAGE,
        payload: res.data,
      });
      dispatch({
        type: ADD_OUTS,
        payload: 1,
      });
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const getMessages = (member, user, page) => (dispatch, getState) => {
  dispatch(setMessagesLoading());

  axios
    .get(
      `${API}/messages?member=${member}&user=${user}&page=${page}`,
      tokenConfig(getState)
    )
    .then((res) =>
      dispatch({
        type: GET_MESSAGES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const getMessagesChatter = (query) => (dispatch, getState) => {
  dispatch(setMessagesLoading());
  axios
    .get(`${API}/messages${query}`, tokenConfig(getState))
    .then((res) =>
      dispatch({
        type: GET_MESSAGES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const takeUser = (channel, chatter, setOpen) => (dispatch, getState) => {
  axios
    .put(`${API}/channels/take/${channel}/${chatter}`, tokenConfig(getState))
    .then((res) => {
      cogoToast.success("Client has been moved to your open dialogues", {
        position: "top-right",
        heading: "The client has been moved",
      });
      setOpen(false);
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
export const addMessage = (message) => (dispatch) => {
  dispatch({
    type: ADD_MESSAGE,
    payload: message,
  });
};

export const setChannelsLoading = () => {
  return {
    type: CHANNELS_LOADING,
  };
};

export const setMessagesLoading = () => {
  return {
    type: MESSAGES_LOADING,
  };
};
