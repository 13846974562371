import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { takeUser } from "../../redux/actions/messengerActions";
import { connect } from "react-redux";
function TakeUser(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    const { user } = props.auth;
    props.takeUser(props.data._id, user._id, setOpen);
  };

  return (
    <React.Fragment>
      <Button
        size="small"
        color="primary"
        variant="outlined"
        onClick={handleClickOpen}
      >
        Take
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are u sure u want to take this client?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You can put this client in waiting room at any time again!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { takeUser })(TakeUser);
