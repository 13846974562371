import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Logo from "../components/Logo";
import styled from "styled-components";
import { connect } from "react-redux";
import { login } from "../redux/actions/authActions";
import { clearErrors } from "../redux/actions/errorActions";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import Alert from "@material-ui/lab/Alert";
import GlobalLoader from "../components/GlobalLoader";
import PropTypes from "prop-types";
const Auth = styled.div`
  display: flex;
  height: 100vh;
`;

const Left = styled.div`
  width: 50%;
  height: 100vh;
  background-image: url("/images/auth.jpg");
  background-size: cover;
  background-position: center center;

  @media (max-width: 960px) {
    display: none;
  }
`;
const Right = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .auth--form {
    max-width: 400px;

    .auth--submit {
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }

  @media (max-width: 960px) {
    width: 100%;

    .auth--form {
      padding: 30px;
    }
  }
`;
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: null,
    };
  }

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;
    if (error !== prevProps.error) {
      // Check for register error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg });
      } else {
        this.setState({ msg: null });
      }
    }
    if (isAuthenticated === true) {
      this.props.history.push("/");
    }
  }

  _handleSubmit = (values, formikApi) => {
    const data = {
      username: values.username,
      password: values.password,
    };
    this.props.login(data, formikApi);
  };

  render() {
    return (
      <Auth>
        <Left></Left>
        <Right>
          <div className="auth--form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Logo
                  style={{
                    width: 140,
                  }}
                />
                {this.state.msg && (
                  <Alert variant="filled" severity="error">
                    {this.state.msg}
                  </Alert>
                )}
              </Grid>
            </Grid>

            <Formik
              initialValues={{
                username: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string().required("Required"),
                password: Yup.string().required("Required"),
              })}
              onSubmit={this._handleSubmit}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  {isSubmitting && <GlobalLoader></GlobalLoader>}
                  <Field
                    component={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                  />
                  <Field
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Password "
                    type="password"
                    id="password"
                  />
                  <br />
                  <br />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="auth--submit"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Sign In
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Right>
      </Auth>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
});

export default connect(mapStateToProps, { login, clearErrors })(SignIn);
