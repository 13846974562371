import React, { Component } from "react";
import { connect } from "react-redux";
import { getCoins } from "../redux/actions/coinsActions";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import Header from "../components/Page/Header";
import Tooltip from "@material-ui/core/Tooltip";
import AddNew from "../components/Coins/AddNew";
import Delete from "../components/Coins/Delete";
import Edit from "../components/Coins/Edit";
class Coins extends Component {
  render() {
    const { coins, data, loading } = this.props.coin;
    const columns = [
      {
        id: "title",
        Header: "Title",
        accessor: (d) => d._id,
        Cell: (props) => <strong>{props.original.title}</strong>,
      },
      {
        id: "coins",
        Header: "Coins",
        accessor: (d) => d._id,
        Cell: (props) => <strong>{props.original.coins}</strong>,
      },
      {
        id: "price",
        Header: "Price",
        accessor: (d) => d._id,
        Cell: (props) => <strong>{props.original.price} €</strong>,
      },

      {
        id: "sold",
        Header: "Sold",
        accessor: (d) => d._id,
        Cell: (props) => (
          <strong>{props.original.sold ? props.original.sold : "0"}</strong>
        ),
      },
      {
        id: "show",
        Header: "Show",
        accessor: (d) => d._id,
        Cell: (props) => <strong>{props.original.show ? "Yes" : "No"}</strong>,
      },

      {
        id: "actions",
        Header: "Actions ",
        accessor: (d) => d._id,
        Cell: (props) => (
          <Actions>
            <Tooltip arrow title="Edit this Coin Pack">
              <Edit data={props.original} />
            </Tooltip>
            <Tooltip arrow title="Delete this Coin Pack">
              <Delete toDelete={props.original._id} />
            </Tooltip>
          </Actions>
        ),
      },
    ];

    return (
      <React.Fragment>
        <Header title="Coins" size={data.total}>
          <AddNew />
        </Header>
        <Table
          data={coins}
          columns={columns}
          loading={loading}
          page={!data.pagination ? 0 : data.pagination.current - 1}
          pages={!data.pagination ? 1 : data.pagination.total}
          onFetchData={(state) => {
            var queryString = state.filtered
              .map((key) => key.id + "=" + key.value)
              .join("&");
            let query = `?page=${state.page + 1}&${queryString}`;
            this.props.getCoins(query);
          }}
          noDataText="No coins"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  coin: state.coin,
});

export default connect(mapStateToProps, { getCoins })(Coins);
