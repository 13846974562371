module.exports = {
  API: "https://api.enjoyflirt.com/api/v1",
  APIROOT: "wss://api.enjoyflirt.com",
  WEB: "https://enjoyflirt.com",
  DASHBOARD: "https://admin.enjoyflirt.com",
  PRODUCTION: true,
  drawerWidth: 240,
};

// module.exports = {
//   API: "http://localhost:8080/api/v1",
//   APIROOT: "ws://localhost:8080",
//   WEB: "http://localhost:3000",
//   DASHBOARD: "http://localhost:3000",
//   PRODUCTION: false,
//   drawerWidth: 240,
// };
