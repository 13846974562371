import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Header from "../Modal/Header";
import Actions from "../Modal/Actions";
import Content from "../Modal/Content";
import GlobalLoader from "../GlobalLoader";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { updateUser } from "../../redux/actions/usersActions";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    outline: "none",
    minWidth: 500,
    maxWidth: "500px",
  },
}));

function EditAdmin(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { data } = props;
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const _handleSubmit = (values, formikApi) => {
    const { firstname, lastname, phone } = values;
    const formData = {
      profile: {
        firstname,
        lastname,
        name: firstname + " " + lastname,
      },
      phone,
    };
    props.updateUser(data._id, formData, formikApi, setOpen);
  };

  return (
    <React.Fragment>
      <span onClick={handleOpen}>
        <EditIcon></EditIcon>
      </span>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={{
                firstname: data.profile.firstname,
                lastname: data.profile.lastname,
                phone: data.phone,
              }}
              validationSchema={Yup.object().shape({
                firstname: Yup.string().required("Required"),
                lastname: Yup.string().required("Required"),
                phone: Yup.string(),
              })}
              onSubmit={_handleSubmit}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  {isSubmitting && <GlobalLoader></GlobalLoader>}
                  <Header title="Edit admin" />
                  <Content>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type="text"
                          margin="normal"
                          fullWidth
                          id="firstname"
                          label="Firstname"
                          name="firstname"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type="text"
                          margin="normal"
                          fullWidth
                          id="lastname"
                          label="Lastname "
                          name="lastname"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Field
                          component={TextField}
                          type="text"
                          margin="normal"
                          fullWidth
                          id="phone"
                          label="Phone"
                          name="phone"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Content>
                  <Actions>
                    <Button
                      variant="contained"
                      onClick={handleClose}
                      style={{ marginRight: 15 }}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Update
                    </Button>
                  </Actions>
                </Form>
              )}
            </Formik>
          </Paper>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}

export default connect((state) => state, { updateUser })(EditAdmin);
