import axios from "axios";
import {
  GET_COUPONS,
  GET_COUPON,
  ADD_COUPON,
  COUPONS_LOADING,
  DELETE_COUPON,
} from "../types";
import cogoToast from "cogo-toast";
import { API } from "../../config";
import { tokenConfig } from "./authActions";
import { returnErrors } from "./errorActions";

export const getCoupons = (query) => (dispatch) => {
  dispatch(setCouponsLoading());
  axios
    .get(`${API}/coupons${query}`)
    .then((res) =>
      dispatch({
        type: GET_COUPONS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const getCoupon = (id) => {
  return (dispatch) => {
    axios
      .get(`${API}/coupons/${id}`)
      .then((response) => {
        dispatch({
          type: GET_COUPON,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(
          dispatch(returnErrors(error.response.data, error.response.status))
        );
      });
  };
};

export const addCoupon = (data) => (dispatch, getState) =>
  new Promise(function (resolve, reject) {
    axios
      .post(`${API}/coupons`, data, tokenConfig(getState))
      .then((response) => {
        dispatch({
          type: ADD_COUPON,
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((error) => {
        cogoToast.error("Something went wrong", {
          position: "top-right",
        });
        reject(error);
      });
  });

export const deleteCoupon = (id) => (dispatch, getState) =>
  new Promise(function (resolve, reject) {
    axios
      .delete(`${API}/coupons/${id}`, tokenConfig(getState))
      .then((response) => {
        dispatch({
          type: DELETE_COUPON,
          payload: id,
        });
        resolve(response.data);
      })
      .catch((error) => {
        cogoToast.error("Something went wrong", {
          position: "top-right",
        });
        reject(error);
      });
  });

export const setCouponsLoading = () => {
  return {
    type: COUPONS_LOADING,
  };
};
