import React from "react";
import { connect } from "react-redux";
import { getOrders } from "../../redux/actions/orderActions";
import Table from "../Table";
import Card from "../Card";
import Moment from "react-moment";

function getPrice(amount, percent, cents) {
  var totalValue = amount * ((100 - percent) / 100 - cents);

  return totalValue.toFixed(2);
}

function Payments(props) {
  const { orders, data, loading } = props.order;
  const columns = [
    {
      id: "product",
      Header: "Coin Pack ",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.product && props.original.product.title}
        </strong>
      ),
    },
    {
      id: "price",
      Header: "Price",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.product && props.original.product.price} €
        </strong>
      ),
    },
    {
      id: "profit",
      Header: "Profit",
      accessor: (d) => d._id,
      Cell: (props) => (
        <React.Fragment>
          {props.original.type === "onlinebanktransfer" && (
            <React.Fragment>
              {getPrice(props.original.product.price, 2.97, 0.25)}€
            </React.Fragment>
          )}

          {props.original.type === "banktransfer" && (
            <React.Fragment>
              {getPrice(props.original.product.price, 1.68, 0.3)}€
            </React.Fragment>
          )}

          {props.original.type === "paysafe" && (
            <React.Fragment>
              {getPrice(props.original.product.price, 15, 0)}€
            </React.Fragment>
          )}
          {props.original.type === "mobilepay" && (
            <React.Fragment>
              {props.original.product.price >= 4.99 &&
                props.original.product.price < 9.99 &&
                "2.73"}
              {props.original.product.price >= 9.99 &&
                props.original.product.price < 14.99 &&
                "5.46"}
              {props.original.product.price >= 14.99 &&
                props.original.product.price < 19.99 &&
                "8.19"}
              {props.original.product.price >= 19.99 &&
                props.original.product.price < 24.99 &&
                "10.92"}
              {props.original.product.price >= 24.99 &&
                props.original.product.price < 29.99 &&
                "13.65"}
              {props.original.product.price >= 29.99 && "16.38"} €
            </React.Fragment>
          )}
        </React.Fragment>
      ),
    },
    {
      id: "coins",
      Header: "Coins",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.product && props.original.product.coins} Coins
        </strong>
      ),
    },
    {
      id: "type",
      Header: "Type",
      accessor: (d) => d._id,
      Cell: (props) => <strong>{props.original.type}</strong>,
    },
    {
      id: "date",
      Header: "Date",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <Moment format="DD-MM-YYYY HH:mm">{props.original.createdAt}</Moment>
        </strong>
      ),
    },
  ];

  return (
    <Card title="Successful payments" style={{ marginTop: 15 }}>
      <Table
        data={orders}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&user=${
            props.userId
          }&${queryString}`;
          props.getOrders(query);
        }}
        noDataText="No payments"
        minRows={5}
      />
    </Card>
  );
}

const mapStateToProps = (state) => ({
  order: state.order,
});

export default connect(mapStateToProps, { getOrders })(Payments);
