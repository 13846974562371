import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import useSWR from "swr";
import axios from "axios";
import { API } from "../../config";
import Loader from "../LoaderPage";

function Card({ number, title }) {
  return (
    <Paper style={{ padding: 30, textAlign: "center" }}>
      <h1>{number}</h1>
      <p style={{ fontSize: 18 }}>{title}</p>
    </Paper>
  );
}

function Admin(props) {
  const { data, error } = useSWR(`${API}/info/admin`, axios);
  if (error) return <div></div>;
  if (!data) return <Loader />;
  const info = data.data;
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card title="Total Clients" number={info.users} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="New Clients" number={info.newUsers} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="In" number={info.ins} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="Out" number={info.outs} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="Active user" number={info.activeUsers} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card title="Sent Gifts" number={info.sentGifts} />
        </Grid>

        <Grid item xs={12} md={3}>
          <Card title="Active Dialogues" number={info.activeDialogues} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Admin);
