import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getUsers } from "../redux/actions/usersActions";
import Table from "../components/Table";
import Actions from "../components/Table/Actions";
import Header from "../components/Page/Header";
import Moment from "react-moment";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import ChangePassword from "../components/User/ChangePassword";
import styled from "styled-components";
import DisableAccount from "../components/User/DisableAccount";
import MemberAvatar from "./client/MemberAvatar";
import moment from "moment";

const PaymentsChip = styled.div`
  background: ${(props) => (props.done ? "#00e808" : "#fc1c03")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function Payments({ done, count, amount }) {
  return (
    <PaymentsChip done={done}>
      {amount.toFixed(2)} € in {count}
    </PaymentsChip>
  );
}

const DeletedChipAdmin = styled.div`
  background: ${(props) => (props.done ? "#fc1c03" : "#ccc")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function DeletedAdmin({ done }) {
  return <DeletedChipAdmin done={done}>{done ? "Yes" : "No"}</DeletedChipAdmin>;
}

const VerifiedChip = styled.div`
  background: ${(props) => (props.done ? "#00e808" : "#fc1c03")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function Verified({ done, facebook }) {
  let verified = done;

  if (facebook) {
    verified = true;
  }
  return (
    <VerifiedChip done={facebook ? true : done ? true : false}>
      {verified ? "Yes" : "No"}
    </VerifiedChip>
  );
}

function Clients(props) {
  const date = moment("01.01.2019");
  const [startDate, setStartDate] = React.useState(date);
  const [endDate, setEndDate] = React.useState(moment(new Date()));
  const [filters, setFilters] = React.useState([]);
  const [defaultFilters, setDefaultFilters] = React.useState([]);
  const [hasFilters, setHasFilters] = useState(false);
  const [gettingFilters, setGettingFilters] = useState(true);
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    function findIndexByProperty(data, key, value) {
      for (var i = 0; i < data.length; i++) {
        if (data[i][key] == value) {
          return i;
        }
      }
      return -1;
    }

    var filterdata = sessionStorage.getItem("clientsFilters");
    if (filterdata) {
      const filterData = JSON.parse("[" + filterdata + "]");
      let newFilters = filters;
      newFilters = filterData[0];
      setDefaultFilters(newFilters);
      setHasFilters(true);
      setGettingFilters(false);

      const startIndex = findIndexByProperty(newFilters, "id", "registerStart");
      const endIndex = findIndexByProperty(newFilters, "id", "registerEnd");

      if (startIndex > -1) {
        setStartDate(moment(newFilters[startIndex].value));
      }
      if (endIndex > -1) {
        setEndDate(moment(newFilters[endIndex].value));
      }
    } else {
      setHasFilters(false);
      setGettingFilters(false);
    }
  }, []);

  const onFromStart = (date) => {
    setStartDate(date);
    console.log(date);
    var start = moment(date).startOf("day");
    let newFilters = filters;
    console.log(filters.filtered);
    const registerStart = { id: "registerStart", value: start };
    function findIndexByProperty(data, key, value) {
      for (var i = 0; i < data.length; i++) {
        if (data[i][key] == value) {
          return i;
        }
      }
      return -1;
    }

    if (newFilters && newFilters.filtered) {
      var startIndex = findIndexByProperty(
        newFilters.filtered,
        "id",
        "registerStart"
      );
      if (startIndex > -1) {
        newFilters.filtered[startIndex] = registerStart;
      } else {
        newFilters.filtered.push(registerStart);
      }
      if (start.isValid()) {
        setFilters(newFilters);
        handleFetch(newFilters);
      }
    }
  };
  const onToStart = () => {};

  const getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      let color = "transparent";

      if (rowInfo.original.duplicate) {
        color = "rgba(245, 242, 66, 0.2)";
      }
      if (rowInfo.original.deleted) {
        color = "rgba(181, 0, 0, 0.2)";
      }

      if (rowInfo.original.duplicate && rowInfo.original.deleted) {
        color = "rgba(84, 90, 255, 0.2)";
      }

      return {
        style: {
          background: color,
        },
      };
    }
    return {};
  };

  const handleFetch = (state) => {
    setFilters(state);
    setDefaultFilters(state.filtered);
    sessionStorage.setItem("clientsFilters", JSON.stringify(state.filtered));
    setIsInitial(false);
    if (gettingFilters === false && isInitial === true) {
      if (hasFilters === true) {
        var queryString = defaultFilters
          .map((key) => key.id + "=" + encodeURIComponent(key.value))
          .join("&");
        let query = `?page=${state.page + 1}&type=1&${queryString}`;
        props.getUsers(query);
      } else {
        var queryString2 = state.filtered
          .map((key) => key.id + "=" + encodeURIComponent(key.value))
          .join("&");
        let query = `?page=${state.page + 1}&type=1&${queryString2}`;
        props.getUsers(query);
      }
    }
    if (!isInitial) {
      console.log("started");
      var queryString3 = state.filtered
        .map((key) => key.id + "=" + encodeURIComponent(key.value))
        .join("&");
      let query = `?page=${state.page + 1}&type=1&${queryString3}`;
      props.getUsers(query);
    }
  };

  const clearFilters = () => {
    let newFilters = filters;
    newFilters.filtered = [];
    setFilters(newFilters);
    handleFetch(newFilters);

    setStartDate(date);
    setEndDate(moment(new Date()));
  };
  const refreshData = () => {
    handleFetch(filters);
  };

  const { users, data, loading } = props.users;
  const columns = [
    {
      id: "image",
      Header: "Bild",
      accessor: (d) => d._id,
      width: 80,
      Cell: (props) => (
        <React.Fragment>
          <Link to={`/client/${props.original._id}`}>
            {props.original.deleted ? (
              <MemberAvatar
                src={props.original.profile.picture}
                alt={props.original.profile.name}
                online={false}
              />
            ) : (
              <MemberAvatar
                src={props.original.profile.picture}
                alt={props.original.profile.name}
                online={props.original.online}
              />
            )}
          </Link>
        </React.Fragment>
      ),
    },
    {
      id: "name",
      Header: "Kunden ",
      filterable: true,
      accessor: (d) => d._id,
      Cell: (props) => (
        <React.Fragment>
          <Link to={`/client/${props.original._id}`}>
            {props.original.online === true ? (
              <strong>{props.original.username}</strong>
            ) : (
              <span>{props.original.username}</span>
            )}
          </Link>
        </React.Fragment>
      ),
    },

    {
      id: "email",
      Header: "E-mail",
      filterable: true,
      accessor: (d) => d._id,
      Cell: (props) => (
        <span style={{ textTransform: "lowercase" }}>
          {props.original.email}
        </span>
      ),
    },
    {
      id: "zip",
      Header: "PLZ",
      accessor: (d) => d._id,
      Cell: (props) => <span>{props.original.zip}</span>,
    },
    {
      id: "ip",
      filterable: true,
      Header: "IP",
      accessor: (d) => d._id,
      Cell: (props) => <span>{props.original.ip}</span>,
    },
    {
      id: "createdAt",
      Header: "Seit",
      accessor: (d) => d._id,
      Cell: (props) => (
        <span>
          <Moment format="DD-MM-YYYY HH:mm">{props.original.createdAt}</Moment>
        </span>
      ),
    },
    {
      id: "coins",
      Header: "Coins",
      accessor: (d) => d._id,
      Cell: (props) => <span>{props.original.coins}</span>,
    },
    {
      id: "emailVerified",
      Header: "Verified ",
      accessor: (d) => d._id,
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Show All </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      ),

      Cell: (props) => (
        <Verified
          done={props.original.emailVerified}
          facebook={props.original.facebook}
        />
      ),
    },
    {
      id: "deletedAdvertisers",
      Header: "Deleted Advertisers",
      filterable: true,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Show All </option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      ),

      accessor: (d) => d._id,
      Cell: (props) => <DeletedAdmin done={props.original.deletedAdmin} />,
    },
    {
      id: "payments",
      Header: "Payments ",
      filterable: true,
      accessor: (d) => d._id,

      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="">Show All </option>
          <option value="true">With payments</option>
          <option value="false">Without payments</option>
        </select>
      ),

      Cell: (props) => (
        <React.Fragment>
          <Payments
            done={props.original.payments}
            count={props.original.paymentsDone}
            amount={props.original.paymentsAmount}
          />
        </React.Fragment>
      ),
    },
    {
      id: "refname",
      Header: "Referer name",
      accessor: (d) => d._id,
      Cell: (props) => (
        <React.Fragment>
          {props.original.referer ? (
            <Link to={`/advertiser/${props.original.referer._id}`}>
              <strong>{props.original.referer.username}</strong>
            </Link>
          ) : (
            "No referer"
          )}
        </React.Fragment>
      ),
    },
    {
      id: "actions",
      Header: "Actions ",
      accessor: (d) => d._id,
      Cell: (props) => (
        <Actions>
          <Tooltip arrow title="Change password">
            <ChangePassword userId={props.original._id} />
          </Tooltip>
          <Tooltip arrow title="Delete user from advertisers">
            <DisableAccount userId={props.original._id} />
          </Tooltip>
        </Actions>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header title="Kunden" size={data.total} icon={<GroupIcon />} />

      <Table
        data={users}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&type=1&${queryString}`;
          props.getUsers(query);
        }}
        defaultFiltered={defaultFilters}
        noDataText="No clients"
        getTrProps={getTrProps}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  users: state.users,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { getUsers })(Clients);
