import React from "react";
import Header from "../components/Page/Header";
import useSWR, { useSWRPages } from "swr";
import axios from "axios";
import { API } from "../config";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import MemberAvatar from "./client/MemberAvatar";
import TakeUser from "../components/WaitingRoom/TakeUser";
export default () => {
  const { pages, isLoadingMore, isReachingEnd, loadMore } = useSWRPages(
    // page key
    "waiting--room",

    ({ offset, withSWR }) => {
      const { data: projects } = withSWR(
        useSWR(
          `${API}/channels?page=${offset || 1}&waitingRoom=true&limit=10`,
          axios,
          {
            refreshInterval: 2000,
          }
        )
      );

      if (!projects) {
        return (
          <div style={{ padding: 15, textAlign: "center" }}>Loading...</div>
        );
      }

      const projectData = projects.data.data;
      return projectData.map((project) => (
        <TableRow key={project._id}>
          <TableCell component="th" scope="row">
            <MemberAvatar
              src={project.member.profile.picture}
              alt={project.member.username}
              online={project.member.online}
            />
          </TableCell>
          <TableCell component="th" scope="row">
            {project.member.username}
          </TableCell>
          <TableCell align="left">{project.lastMessage}</TableCell>

          <TableCell align="right">{project.user.username}</TableCell>

          <TableCell align="right">
            <Avatar
              src={project.user.profile.picture}
              style={{ float: "right" }}
            />
          </TableCell>
          <TableCell align="right">
            <TakeUser data={project} />
          </TableCell>
        </TableRow>
      ));
    },

    // get next page's offset from the index of current page
    (SWR, index) => {
      // there's no next page
      if (
        SWR.data &&
        SWR.data.data.pagination.total <= SWR.data.data.pagination.current
      )
        return null;

      // offset = pageCount × pageSize
      return SWR.data && SWR.data.data.pagination.current + 1;
    },

    // deps of the page component
    []
  );

  return (
    <React.Fragment>
      <Header title="In attesa " />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Image </TableCell>
              <TableCell>Client</TableCell>
              <TableCell align="left">Message </TableCell>
              <TableCell align="right">Profile</TableCell>
              <TableCell align="right">Image </TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{pages}</TableBody>
        </Table>
      </TableContainer>

      <Button
        onClick={loadMore}
        disabled={isReachingEnd || isLoadingMore}
        style={{ margin: "30px auto", display: "block" }}
      >
        {isLoadingMore
          ? ". . ."
          : isReachingEnd
          ? "No more customers in the waiting room"
          : "Load more "}
      </Button>
    </React.Fragment>
  );
};
