import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ImageUpload from "../ImageUpload";
import { connect } from "react-redux";
import { uploadAvatar } from "../../redux/actions/usersActions";
function UploadAvatar(props) {
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
    let reader = new FileReader();

    reader.onloadend = () => {
      setFilePreview(reader.result);
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  const updateFile = () => {
    var formData = new FormData();
    formData.append("file", file);
    props.uploadAvatar(props.userId, formData);
  };

  return (
    <React.Fragment>
      <ImageUpload
        onImageChange={onFileChange}
        imagePreviewUrl={filePreview}
        currentPicture={props.currentPicture}
      />
      <Button
        color="primary"
        variant="contained"
        fullWidth
        onClick={updateFile}
        style={{ marginTop: 10 }}
      >
        Update profile picture
      </Button>
    </React.Fragment>
  );
}

export default connect((state) => state, { uploadAvatar })(UploadAvatar);
