import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Pages from "./Pages";
import { connect } from "react-redux";
import GlobalLoader from "../components/LoaderPage";
import ScrollToTop from "./ScrollToTop";

function Routes({ theme, setTheme, auth, Component }) {
  const { isAuthenticated, isLoading } = auth;

  return (
    <Router>
      {isLoading ? (
        <GlobalLoader />
      ) : (
        <Route
          render={({ location }) => (
            <React.Fragment>
              <ScrollToTop />
              <Pages
                location={location}
                isAuthenticated={isAuthenticated}
                setTheme={setTheme}
                theme={theme}
              />
            </React.Fragment>
          )}
        />
      )}
    </Router>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(Routes);
