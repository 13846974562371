import React from "react";
import { connect } from "react-redux";
import { getOrders } from "../redux/actions/orderActions";
import Table from "../components/Table";
import Header from "../components/Page/Header";
import Avatar from "@material-ui/core/Avatar";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StatusWrapper = styled.div`
  text-align: center;
  padding: 8px;
  background: ${(props) => props.color};
  width: 100%;
  border-radius: 4px;
  color: #fff;
`;

function Status({ type }) {
  let color;
  if (type === "INIT") {
    color = "#ccc";
  } else if (type === "OPEN") {
    color = "#e2e609";
  } else if (type === "PAID") {
    color = "#3cd665";
  } else if (type === "OVERPAID") {
    color = "#d10ab7";
  } else if (type === "CLOSED") {
    color = "#c91e1e";
  } else {
    color = "#3cd665";
  }
  return <StatusWrapper color={color}>{type ? type : "PAID"}</StatusWrapper>;
}

function getPrice(amount, percent, cents) {
  var totalValue = amount * ((100 - percent) / 100 - cents);

  return totalValue.toFixed(2);
}

function Order(props) {
  const { orders, data, loading } = props.order;
  const columns = [
    {
      id: "image",
      Header: "Bild",
      accessor: (d) => d._id,
      width: 80,
      Cell: (props) => (
        <React.Fragment>
          {props.original.user && (
            <Link to={`/client/${props.original.user._id}`}>
              <Avatar
                src={props.original.user && props.original.user.profile.picture}
                alt={props.original.user && props.original.user.username}
              />
            </Link>
          )}
        </React.Fragment>
      ),
    },
    {
      id: "client",
      Header: "Kunden ",
      accessor: (d) => d._id,
      Cell: (props) => (
        <React.Fragment>
          {props.original.user && (
            <Link to={`/client/${props.original.user._id}`}>
              <strong>
                {props.original.user && props.original.user.username}
              </strong>
            </Link>
          )}
        </React.Fragment>
      ),
    },
    {
      id: "product",
      Header: "Coin Pack ",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.product && props.original.product.title}
        </strong>
      ),
    },
    {
      id: "price",
      Header: "Price",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.product && props.original.product.price} €
        </strong>
      ),
    },

    {
      id: "coins",
      Header: "Coins",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          {props.original.product && props.original.product.coins} Coins
        </strong>
      ),
    },

    {
      id: "type",
      Header: "Type",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong style={{ textTransform: "uppercase" }}>
          {props.original.type}
        </strong>
      ),
    },
    {
      id: "date",
      Header: "Date",
      accessor: (d) => d._id,
      Cell: (props) => (
        <strong>
          <Moment>{props.original.createdAt}</Moment>
        </strong>
      ),
    },

    {
      id: "status",
      Header: "Stato ",
      accessor: (d) => d._id,
      Cell: (props) => <Status type={props.original.paystatus} />,
    },
  ];

  return (
    <React.Fragment>
      <Header title="Payments " size={data.total} />
      <Table
        data={orders}
        columns={columns}
        loading={loading}
        page={!data.pagination ? 0 : data.pagination.current - 1}
        pages={!data.pagination ? 1 : data.pagination.total}
        onFetchData={(state) => {
          var queryString = state.filtered
            .map((key) => key.id + "=" + key.value)
            .join("&");
          let query = `?page=${state.page + 1}&${queryString}`;
          props.getOrders(query);
        }}
        noDataText="No payments"
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  order: state.order,
});

export default connect(mapStateToProps, { getOrders })(Order);
