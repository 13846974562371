import React, { useState } from "react";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/authActions";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import axios from "axios";
import { API } from "../../config";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

function LogOut(props) {
  const { user } = props.auth;
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("Please wait!");
  const logOutUser = () => {
    props.logout();
  };

  const logOutChatter = () => {
    setLoading(true);
    setMsg("Please wait!");
    setInterval(() => setMsg("Do not close this tab..."), 1000);
    setInterval(() => {
      axios
        .get(`${API}/channels/chatter/${user._id}`)
        .then((res) => {
          setMsg(`Moving ${res.data.data} dialogues to waiting room...`);
          setInterval(() => {
            axios
              .post(`${API}/channels/move/${user._id}`)
              .then((response) => {
                setMsg(`Moving ${res.data.data} dialogues to waiting room...`);
                setInterval(() => setMsg("Login you out..."), 1000);
                setInterval(() => props.logout(), 2000);
                setInterval(() => window.location.reload(), 2200);
              })
              .catch((err) => {
                setMsg(
                  "Something went wrong moving the dialogues to waiting room!"
                );
                setInterval(() => setMsg("Please try again!"), 1000);
                setInterval(() => setLoading(false), 2000);
              });
          }, 1000);
        })
        .catch((err) => {
          setMsg("Something went wrong!");
          setInterval(() => setMsg("Please try again!"), 1000);
          setInterval(() => setLoading(false), 2000);
        });
    }, 2000);
  };

  if (user.type === 3) {
    return (
      <React.Fragment>
        <Backdrop
          open={loading}
          style={{ backgroundColor: "#fff", zIndex: 99999999999999 }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="inherit" style={{ color: "#000" }} />
            <span style={{ color: "#000", marginTop: 30 }}>{msg}</span>
          </div>
        </Backdrop>
        <ListItem button onClick={logOutChatter}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
      </React.Fragment>
    );
  }
  return (
    <ListItem button onClick={logOutUser}>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Sign Out" />
    </ListItem>
  );
}

export default connect((state) => state, {
  logout,
})(LogOut);
