import React, { Component } from "react";
import { connect } from "react-redux";
import { getUsers } from "../../redux/actions/usersActions";
import Table from "../../components/Table";
import Header from "../../components/Page/Header";
import Moment from "react-moment";
import GroupIcon from "@material-ui/icons/Group";
import Avatar from "@material-ui/core/Avatar";
import styled from "styled-components";
import { Link } from "react-router-dom";
const OnlineChip = styled.div`
  background: ${(props) => (props.done ? "#00e808" : "#ccc")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function Online({ done }) {
  return <OnlineChip done={done}>{done ? "Online" : "Offline"}</OnlineChip>;
}
const VerifiedChip = styled.div`
  background: ${(props) => (props.done ? "#00e808" : "#fc1c03")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function Verified({ done }) {
  return <VerifiedChip done={done}>{done ? "Yes" : "No"}</VerifiedChip>;
}

const PaymentsChip = styled.div`
  background: ${(props) => (props.done ? "#00e808" : "#fc1c03")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function Payments({ done }) {
  return <PaymentsChip done={done}>{done ? "Yes" : "No"}</PaymentsChip>;
}

const DeletedChip = styled.div`
  background: ${(props) => (props.done ? "#fc1c03" : "#ccc")};
  word-break: break-all;
  padding: 8px 10px;
  border-radius: 3px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
`;
function Deleted({ done }) {
  return <DeletedChip done={done}>{done ? "Yes" : "No"}</DeletedChip>;
}

class Team extends Component {
  render() {
    const { users, data, loading } = this.props.users;
    const { user } = this.props.auth;
    const columns = [
      {
        id: "picture",
        Header: "Bild",
        accessor: (d) => d._id,
        Cell: (props) => (
          <Avatar
            src={props.original.profile ? props.original.profile.picture : ""}
            alt={props.original.profile ? props.original.username : ""}
          />
        ),
      },
      {
        id: "name",
        Header: "Kunde",
        accessor: (d) => d._id,
        Cell: (props) => (
          <strong style={{ textTransform: "lowercase" }}>
            {props.original.profile ? props.original.username : ""}
          </strong>
        ),
      },
      {
        id: "zip",
        Header: "PLZ",
        accessor: (d) => d._id,
        Cell: (props) => <span>{props.original.zip}</span>,
      },

      {
        id: "createdAt",
        Header: "Seit",
        accessor: (d) => d._id,
        Cell: (props) => (
          <span>
            <Moment format="DD-MM-YYYY HH:mm">
              {props.original.createdAt}
            </Moment>
          </span>
        ),
      },
      {
        id: "online",
        Header: "Online",
        accessor: (d) => d._id,
        Cell: (props) => <Online done={props.original.online} />,
      },
      {
        id: "verified",
        Header: "Verified ",
        accessor: (d) => d._id,
        Cell: (props) => <Verified done={props.original.emailVerified} />,
      },
      {
        id: "deleted",
        Header: "Deleted",
        accessor: (d) => d._id,
        Cell: (props) => <Deleted done={props.original.deleted} />,
      },
      {
        id: "payments",
        Header: "Payments ",
        accessor: (d) => d._id,
        Cell: (props) => (
          <React.Fragment>
            <Payments done={props.original.payments} />
          </React.Fragment>
        ),
      },
    ];

    return (
      <React.Fragment>
        <Header title="Users" size={data.total} icon={<GroupIcon />} />
        <Table
          data={users}
          columns={columns}
          loading={loading}
          page={!data.pagination ? 0 : data.pagination.current - 1}
          pages={!data.pagination ? 1 : data.pagination.total}
          onFetchData={(state) => {
            var queryString = state.filtered
              .map((key) => key.id + "=" + key.value)
              .join("&");
            let query = `?page=${state.page + 1}&type=1&ref=${
              user.refId
            }&${queryString}`;
            this.props.getUsers(query);
          }}
          noDataText="No clients"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  auth: state.auth,
});

export default connect(mapStateToProps, { getUsers })(Team);
