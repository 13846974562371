export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const UPDATE_USER = "UPDATE_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOGIN_NOT_ALLOWED = "LOGIN_NOT_ALLOWED";
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_USERS = "GET_USERS";
export const USERS_LOADING = "USERS_LOADING";
export const DELETE_USER = "DELETE_USER";
export const ADD_USER = "ADD_USER";
export const GET_USER = "GET_USER";
export const DESTROY_SESSION = "DESTROY_SESSION";
export const GET_OUTS = "GET_OUTS";
export const ADD_OUTS = "ADD_OUTS";

// Global
export const FETCHING_DATA = "FETCHING_DATA";
export const FETCHING_DATA_DONE = "FETCHING_DATA_DONE";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_DONE = "SET_LOADING_DONE";
export const SET_THEME = "SET_THEME";
export const SET_THEME_STYLES = "SET_THEME_STYLES";

// GIFTS
export const GET_GIFTS = "GET_GIFTS";
export const GET_GIFT = "GET_GIFT";
export const ADD_GIFT = "ADD_GIFT";
export const GIFTS_LOADING = "GIFTS_LOADING";
export const DELETE_GIFT = "DELETE_GIFT";
export const GET_GIFTS_SENT = "GET_GIFTS_SENT";
export const GET_GIFTS_RECEIVED = "GET_GIFTS_RECEIVED";
export const UPDATE_GIFT = "UPDATE_GIFT";

// Coins
export const GET_COINS = "GET_COINS";
export const GET_COIN = "GET_COIN";
export const ADD_COIN = "ADD_COIN";
export const COINS_LOADING = "COINS_LOADING";
export const DELETE_COIN = "DELETE_COIN";
export const UPDATE_COIN = "UPDATE_COIN";

// COUPONS
export const GET_COUPONS = "GET_COUPONS";
export const GET_COUPON = "GET_COUPON";
export const ADD_COUPON = "ADD_COUPON";
export const COUPONS_LOADING = "COUPONS_LOADING";
export const DELETE_COUPON = "DELETE_COUPON";

// ORDERS
export const GET_ORDERS = "GET_ORDERS";
export const ORDERS_LOADING = "ORDERS_LOADING";

// CHANNELS
export const GET_CHANNELS = "GET_CHANNELS";
export const CHANNELS_LOADING = "CHANNELS_LOADING";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const GET_MESSAGES = "GET_MESSAGES";
export const MESSAGES_LOADING = "MESSAGES_LOADING";
export const SET_SOCKET = "SET_SOCKET";
export const LOGING_OUT_CHATTER = "LOGING_OUT_CHATTER";
export const CHATTER_DIALOUGES = "CHATTER_DIALOUGES";



export const GET_ACTIVITY = "GET_ACTIVITY";
export const ACTIVITY_LOADING = "ACTIVITY_LOADING";
export const GET_COINS_ACTIVITY = "GET_COINS_ACTIVITY";
export const COINS_ACTIVITY_LOADING = "COINS_ACTIVITY_LOADING";

