import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import { API } from "../../config";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";

export default function UpdateCoins({ userId, currentCoins }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values, formikAPI) => {
    const { coins } = values;
    axios.put(`${API}/users/${userId}`, { coins: coins }).then((res) => {
      setOpen(false);
      formikAPI.setSubmitting(false);
      window.location.reload();
    });
  };
  return (
    <React.Fragment>
      <IconButton
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        style={{ marginLeft: 15 }}
        size="small"
      >
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <Formik
          initialValues={{
            coins: currentCoins,
          }}
          validationSchema={Yup.object().shape({
            coins: Yup.number().nullable().min(0).required("Required"),
          })}
          onSubmit={handleSubmit}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <DialogTitle>Update coins </DialogTitle>

              <DialogContent>
                <Field
                  component={TextField}
                  type="text"
                  margin="normal"
                  fullWidth
                  label="Coins"
                  name="coins"
                  variant="outlined"
                  style={{ margin: 0, minWidth: 450 }}
                />
              </DialogContent>
              <DialogActions style={{ padding: 20 }}>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={submitForm}
                  color="primary"
                  variant="contained"
                  autoFocus
                >
                  Update
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
