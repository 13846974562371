import axios from "axios";
import {
  GET_USERS,
  GET_USER,
  USERS_LOADING,
  DELETE_USER,
  UPDATE_USER,
  ADD_USER,
  FETCHING_DATA,
  FETCHING_DATA_DONE,
  SET_LOADING,
  SET_LOADING_DONE,
} from "../types";
import { tokenConfig } from "./authActions";
import { returnErrors } from "./errorActions";
import { API } from "../../config";
import cogoToast from "cogo-toast";
export const getUsers = (query) => (dispatch, getState) => {
  dispatch(setUsersLoading());
  dispatch({ type: FETCHING_DATA });
  axios
    .get(`${API}/users${query}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
      dispatch({ type: FETCHING_DATA_DONE });
    })

    .catch((err) => {
      dispatch({ type: FETCHING_DATA_DONE });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getUser = (id) => (dispatch, getState) => {
  dispatch(setUsersLoading());
  dispatch({
    type: FETCHING_DATA,
  });
  dispatch({ type: SET_LOADING });
  axios
    .get(`${API}/users/id/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
      dispatch({ type: FETCHING_DATA_DONE });
      dispatch({ type: SET_LOADING_DONE });
    })
    .catch((err) => {
      dispatch({ type: FETCHING_DATA_DONE });
      dispatch({ type: SET_LOADING_DONE });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const addUser = (user, formikApi, setOpen) => (dispatch, getState) => {
  axios
    .post(`${API}/users`, user, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_USER,
        payload: res.data,
      });
      cogoToast.success("User created successfully", {
        position: "top-right",
        heading: "User created",
      });
      formikApi.setSubmitting(false);
      setOpen(false);
    })
    .catch((err) => {
      cogoToast.error("Could not created User", {
        position: "top-right",
        heading: "Utente non creato",
      });
      formikApi.setSubmitting(false);
      setOpen(false);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const addProfile =
  (data, formikApi, history) => (dispatch, getState) => {
    axios
      .post(`${API}/users`, data, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: ADD_USER,
          payload: res.data,
        });
        cogoToast.success("User created successfully", {
          position: "top-right",
          heading: "User created",
        });
        formikApi.setSubmitting(false);
        history.push(`/profiles/edit/${res.data.data._id}`);
      })
      .catch((err) => {
        formikApi.setSubmitting(false);
        cogoToast.error("Could not created User", {
          position: "top-right",
          heading: "Utente non creato",
        });
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };

export const addContactPerson = (history, id, data) => (dispatch, getState) => {
  axios
    .put(`${API}/users/add-contact/${id}`, data, tokenConfig(getState))
    .then((res) => {
      cogoToast.success("Contact person addedd successfully", {
        position: "top-right",
        heading: "Contact person added",
      });
      history.push("/users/edit/" + id);
    })
    .catch((err) => {
      cogoToast.error("Could not created contact person", {
        position: "top-right",
        heading: "Contact person not created",
      });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const createUserUser = (history, id, data) => (dispatch, getState) => {
  axios
    .post(`${API}/users/add-user/${id}`, data, tokenConfig(getState))
    .then((res) => {
      cogoToast.success("User created successfully", {
        position: "top-right",
        heading: "User created successfully",
      });
      history.push("/users/edit/" + id);
    })
    .catch((err) => {
      cogoToast.error("Could not created User", {
        position: "top-right",
        heading: "Something went wrong",
      });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const removeContactPerson =
  (history, id, data) => (dispatch, getState) => {
    axios
      .put(`${API}/users/remove-contact/${id}`, data, tokenConfig(getState))
      .then((res) => {
        cogoToast.success("Contact person removed successfully", {
          position: "top-right",
          heading: "Contact person removed",
        });
        history.push("/users/edit/" + id);
      })
      .catch((err) => {
        cogoToast.error("Could not removed contact person", {
          position: "top-right",
          heading: "Contact person not removed",
        });
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };

export const updateUser =
  (id, data, formikApi, setOpen) => (dispatch, getState) => {
    axios
      .put(`${API}/users/${id}`, data, tokenConfig(getState))
      .then((res) => {
        cogoToast.success("User updated successfully", {
          position: "top-right",
          heading: "User updated",
        });
        setOpen(false);
        formikApi.setSubmitting(false);
      })
      .catch((err) => {
        cogoToast.error("Could not updated User", {
          position: "top-right",
          heading: "User not updated",
        });
        formikApi.setSubmitting(false);
        setOpen(false);
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
export const updateProfile = (history, id, job) => (dispatch, getState) => {
  axios
    .put(`${API}/users/${id}`, job, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: id,
      });
      cogoToast.success("User updated successfully", {
        position: "top-right",
        heading: "User updated",
      });
      history.push("/profiles");
    })
    .catch((err) => {
      cogoToast.error("Could not updated User", {
        position: "top-right",
        heading: "User not updated",
      });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
export const updatePassword = (id, data, formikApi) => (dispatch, getState) => {
  axios
    .put(`${API}/users/change-password/${id}`, data, tokenConfig(getState))
    .then((res) => {
      cogoToast.success("User password updated successfully", {
        position: "top-right",
        heading: "Password changed",
      });
      formikApi.setSubmitting(false);
    })
    .catch((err) => {
      cogoToast.error("Could not change password", {
        position: "top-right",
        heading: "Password not changed",
      });
      formikApi.setSubmitting(false);
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const deleteUser = (id) => (dispatch, getState) => {
  axios
    .delete(`${API}/users/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_USER,
        payload: id,
      });
      cogoToast.success("User deleted successfully", {
        position: "top-right",
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      cogoToast.error(err.response.data.error, {
        position: "top-right",
        heading: "Could not delete",
      });
    });
};

export const disableUser = (id, data) => (dispatch, getState) => {
  axios
    .put(`${API}/users/${id}`, data, tokenConfig(getState))
    .then((res) => {
      cogoToast.success("Utente eliminato dagli inserzionisti", {
        position: "top-right",
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      cogoToast.error(err.response.data.error, {
        position: "top-right",
        heading: "Could not delete",
      });
    });
};

export const uploadImage = (id, data) => {
  return (dispatch) => {
    dispatch({ type: SET_LOADING });
    axios
      .post(`${API}/users/image/${id}`, data)
      .then((response) => {
        cogoToast.success("Image updated", {
          position: "top-right",
        });
        window.location.reload();
      })
      .catch((error) => {
        cogoToast.error("Something went wrong, try again", {
          position: "top-right",
        });
      });
  };
};

export const uploadAvatar = (id, data) => {
  return (dispatch) => {
    dispatch({ type: SET_LOADING });
    axios
      .post(`${API}/users/photo/${id}`, data)
      .then((response) => {
        cogoToast.success("Profile Picture updated", {
          position: "top-right",
        });
        window.location.reload();
      })
      .catch((error) => {
        cogoToast.error("Something went wrong, try again", {
          position: "top-right",
        });
      });
  };
};

export const setUsersLoading = () => {
  return {
    type: USERS_LOADING,
  };
};
